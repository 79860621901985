import { Form, Input, Modal, Select } from 'antd';

import React from 'react';
import StringConstants from '../../constants/StringConstants';
import { getDisplayString } from '../../utils/DisplayUtils';

const { Option } = Select;

class AddUserModal extends React.Component {
  render() {
    const { visible, creatingUser } = this.props;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
    return (
      <Modal
        visible={visible}
        title={'Add New User'}
        onOk={() => this.addUserForm.submit()}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{
          loading: creatingUser,
        }}
        cancelButtonProps={{ loading: creatingUser }}
      >
        <Form
          {...layout}
          onFinish={this.props.onSubmit}
          ref={(ref) => (this.addUserForm = ref)}
          className='mt-30'
        >
          <Form.Item
            label='Name'
            name='name'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter a name !',
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter a email !',
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Role'
            name='role'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please choose a role !',
              },
            ]}
            hasFeedback
          >
            <Select
              onChange={(value) => this.setState({ selectedRole: value })}
            >
              {Object.keys(StringConstants.UserRole).map((role) => {
                return (
                  <Option key={role} value={role}>
                    <span
                      style={{
                        color:
                          role === StringConstants.UserRole.SUPER_ADMIN
                            ? '#52c41a'
                            : '#fa8c16',
                      }}
                    >
                      {' '}
                      {getDisplayString(role)}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default AddUserModal;
