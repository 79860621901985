import { Column, Row } from 'simple-flexbox';
import { Spin, Typography } from 'antd';

import React from 'react';

const LoadingComponent = (props) => {
  return (
    <Row vertical='center' horizontal='center' className='loading-row'>
      <Column className='text-center'>
        <Spin
          size='large'
          indicator={
            <img
              src={require('../../src/assets/images/loader.gif').default}
              className='loading-gif'
              alt='Loading GIF'
            />
          }
        />
        <Typography.Text className='loading-text'>
          {props.title ? props.title : 'Loading...'}
        </Typography.Text>
      </Column>
    </Row>
  );
};

export default LoadingComponent;
