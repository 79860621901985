import { Form, Input, Modal } from 'antd';

import React from 'react';

class BoardCreationModal extends React.Component {
  render() {
    const { visible } = this.props;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
    return (
      <Modal
        visible={visible}
        title={'Create New Board'}
        onOk={() => this.boardCreationFormRef.submit()}
        onCancel={() => this.props.onCancel()}
      >
        <Form
          {...layout}
          onFinish={this.props.onSubmit}
          ref={(ref) => (this.boardCreationFormRef = ref)}
          className='mt-30'
        >
          <Form.Item
            label='Name'
            name='name'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter a name !',
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Description'
            name='description'
            labelAlign='left'
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default BoardCreationModal;
