import { Link, withRouter } from 'react-router-dom';
import {
  getUserDataFromCookie,
  isAuthenticatedUser,
} from '../utils/auth/AuthUtils';
import { isUserActive, isUserDetailAvailable } from '../helpers/user';

import BreadCrumbs from './BreadCrumbs';
import NoAccessComponent from './NoAccessComponent';
import React from 'react';
import RouteConstants from '../constants/RouteConstants';
import StringConstants from '../constants/StringConstants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteToken } from '../utils/auth/auth';
import { isEmpty } from 'lodash';
import moment from 'moment';

const SecureComponent = (
  Page,
  accessToRole = [
    StringConstants.UserRole.SUPER_ADMIN,
    StringConstants.UserRole.EDITOR,
  ]
) => {
	
  class SecureComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: null,
      };
    }

    componentDidMount() {
      const isUserLoggedIn = isAuthenticatedUser();

      this.setState({
        isLoggedIn: isUserLoggedIn,
      });

      const loggedInUserData = getUserDataFromCookie();
      let route = RouteConstants.LOGIN;
      if (!isUserLoggedIn) {
        if (!isEmpty(loggedInUserData)) {
          // verify expiration of login token
          if (moment(new Date(loggedInUserData.exp)).isBefore(moment())) {
            deleteToken();
            sessionStorage.removeItem('state');
          }
        } else {
          if (this.props.location && this.props.location.pathname)
            route += 'redirect?redirect=' + this.props.location.pathname;
        }

        this.props.history.replace(route);
      } else {
        if (
          !isUserDetailAvailable() &&
          this.props.location &&
          this.props.location.pathname
        ) {
          route += 'redirect?redirect=' + this.props.location.pathname;
          this.props.history.replace(route);
        }
      }
    }

    checkAccess = () => {
      return (
        isUserActive() &&
        (accessToRole
          ? accessToRole.length === 0
            ? false
            : accessToRole.includes(this.props.user.role)
          : false)
      );
    };

    render() {
      const { isLoggedIn } = this.state;
      if (isLoggedIn === null) return <div style={{ height: '95vh' }}></div>;
      if (!isLoggedIn) {
        return (
          <div>
            <p>
              You're not logged in to the system. Try to{' '}
              <Link to={RouteConstants.LOGIN}> Login</Link>
            </p>
          </div>
        );
      }

      if (!this.checkAccess()) return <NoAccessComponent />;

      return (
        <>
          <BreadCrumbs />
          <Page {...this.state} {...this.props} />
        </>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.user,
    };
  };

  return compose(withRouter, connect(mapStateToProps, null))(SecureComponent);
};

export default SecureComponent;
