import { call, put, select, takeEvery } from 'redux-saga/effects';

import RouteConstants from '../constants/RouteConstants';
import StringConstants from '../constants/StringConstants';
import UserService from '../services/UserService';
import { actionTypes } from '../actions/types';
import { deleteToken } from '../utils/auth/auth';
import { getUser } from '../selectors';
import { saveState } from '../utils/misc/SessionStorageUtils';

export default function* watchFetchUser() {
  yield takeEvery(actionTypes.FETCH_USER_REQUEST, fetchUser);
}

function* fetchUser(action) {
  try {
    const user = yield select(getUser);

    // Make API call only if user state doesn't exist or failed before
    if (Object.keys(user).length === 0 || ('isError' in user && user.isError)) {
      const response = yield call(UserService.getUserProfile);

      if (response.status === 200) {
        let userProfile = response.data;

        // dispatch success action
        yield put({ type: actionTypes.FETCH_USER_SUCCESS, user: userProfile });
        yield put({
          type: actionTypes.SET_REDIRECT_PATH,
          redirectPath: action.redirectPath,
        });
      }
      // dispatch failed action
      else {
        yield put({
          type: actionTypes.FETCH_USER_FAILURE,
          payload: 'Fetch user profile error ',
        });
        yield put({
          type: actionTypes.SET_REDIRECT_PATH,
          redirectPath: action.redirectPath,
        });
        deleteToken();
      }
    }

    saveState(user);

    yield put({ type: actionTypes.FETCH_USER_SUCCESS, user });
    yield put({
      type: actionTypes.SET_REDIRECT_PATH,
      redirectPath: action.redirectPath,
    });
  } catch (err) {
    yield put({
      type: actionTypes.FETCH_USER_FAILURE,
      payload: 'Fetch user profile error ',
    });
    yield put({
      type: actionTypes.SET_REDIRECT_PATH,
      redirectPath: RouteConstants.ERROR,
      errorType: StringConstants.UnauthorizedError,
    });
    deleteToken();
  }
}
