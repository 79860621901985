const UrlConstants = {
  LOGIN: {
    USECASE: 'LOGIN',
    URL: '/auth/verify',
  },
  GET_USER_PROFILE: {
    USECASE: 'GET_USER_PROFILE',
    URL: '/user',
  },
  GET_ALL_BOARDS: {
    USECASE: 'GET_ALL_BOARDS',
    URL: '/board',
  },
  CREATE_BOARD: {
    USECASE: 'CREATE_BOARD',
    URL: '/board',
  },
  COPY_BOARD_FORECAST: {
    USECASE: 'COPY_BOARD_FORECAST',
    URL: '/board/:id/copyforecastdata',
  },
  GET_BOARD: {
    USECASE: 'GET_BOARD',
    URL: '/board/:id',
  },
  UPDATE_BOARD: {
    USECASE: 'UPDATE_BOARD',
    URL: '/board/:id',
  },
  DELETE_BOARD: {
    USECASE: 'DELETE_BOARD',
    URL: '/board/:id',
  },
  GET_SPECIFICATIONS: {
    USECASE: 'GET_SPECIFICATIONS',
    URL: '/board/:id/specification',
  },
  GET_LOCATIONS: {
    USECASE: 'GET_LOCATIONS',
    URL: '/board/:id/location',
  },
  GET_BOARD_FORECAST: {
    USECASE: 'GET_BOARD_FORECAST',
    URL: '/board/:id/forecast',
  },
  UPDATE_BOARD_FORECAST: {
    USECASE: 'UPDATE_BOARD_FORECAST',
    URL: '/board/:id/update-forecast',
  },
  FETCH_CHANGE_LOG: {
    USECASE: 'FETCH_CHANGE_LOG',
    URL: '/board/:id/change-logs',
  },
  GET_NAV_FORECAST_INFO: {
    USECASE: 'GET_NAV_FORECAST_INFO',
    URL: '/nav-forecast/info',
  },
  GET_ALL_USERS: {
    USECASE: 'GET_ALL_USERS',
    URL: '/user/-/all',
  },
  CREATE_USER: {
    USECASE: 'CREATE_USER',
    URL: '/user',
  },
  CHANGE_USER_ROLE: {
    USECASE: 'CHANGE_USER_ROLE',
    URL: '/user/:id/change-role',
  },
  UPDATE_USER: {
    USECASE: 'UPDATE_USER',
    URL: '/user',
  },
  REMOVE_USER: {
    USECASE: 'REMOVE_USER',
    URL: '/user/:id/remove',
  },
};

export default UrlConstants;
