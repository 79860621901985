import { actionTypes } from './types';

/**
 * @param {object} payload
 * payload {
 *  avatar_url {string}
 * }
 * @param {string} redirectPath
 */
export const fetchUser = (payload, redirectPath) => {
  return {
    type: actionTypes.FETCH_USER_REQUEST,
    payload: payload,
    redirectPath: redirectPath,
  };
};

/**
 * @param {object} error
 * @param {string} redirectPath
 */
export const fetchUserFailed = (error, redirectPath) => {
  return {
    type: actionTypes.FETCH_USER_FAILURE,
    payload: error,
    redirectPath: redirectPath,
  };
};
