import TableFilterUtils from '../utils/TableFilterUtils';
import store from '../createStore';

export const getReducer = (reducer) => {
  return store.getState() && store.getState()[reducer];
};

export const getField = (reducer, field) => {
  if (!getReducer(reducer)) return false;

  return TableFilterUtils.getProperty(getReducer(reducer), field);
};
