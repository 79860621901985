import './EditLeverModal.css';

import { InputNumber, Modal, Radio, Typography } from 'antd';
import { isEmpty, isUndefined, startCase } from 'lodash';

import React from 'react';
import StringConstants from '../constants/StringConstants';
import { getKeyString } from '../utils/DisplayUtils';

class EditLeverModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSubmit: true,
    };
  }

  renderModellingActions = (data) => {
    const { inventoryType, key } = data;
    let id = data.itemNumber + '_' + data.code + '_' + data.weekTitle;
    let currentInventoryValue = data.weekData[inventoryType][key];

    let minMaxProp =
      inventoryType === 'incomingInventory'
        ? {
            min: 0,
          }
        : {
            max: 0,
          };
    return (
      <>
        <Radio.Group
          className='modelling-actions'
          defaultValue={StringConstants.ModellingActions.REPLACE}
          value={this.state[id + '_currentAction']}
          onChange={(event) => this.onActionChange(data, event.target.value)}
        >
          {Object.keys(StringConstants.ModellingActions).map((action) => (
            <Radio.Button
              value={StringConstants.ModellingActions[action]}
              key={action}
            >
              {StringConstants.ModellingActions[action]}
            </Radio.Button>
          ))}
        </Radio.Group>
        <br />
        {isUndefined(this.state[id + '_currentAction']) ||
        this.state[id + '_currentAction'] ===
          StringConstants.ModellingActions.REPLACE ? (
          <>
            <span>New Value</span>
            <InputNumber
              type='number'
              defaultValue={currentInventoryValue}
              className='inventory-input'
              onChange={(value) =>
                this.setNewInventoryValue(
                  value,
                  id,
                  inventoryType,
                  currentInventoryValue,
                  key
                )
              }
              {...minMaxProp}
            />
          </>
        ) : this.state[id + '_currentAction'] ===
          StringConstants.ModellingActions.ADD_PERCENTAGE ? (
          <>
            <span>Enter percent to add/subtract by </span>
            <InputNumber
              type='number'
              defaultValue={0}
              className='inventory-input'
              onChange={(value) =>
                this.setNewInventoryValue(
                  value,
                  id,
                  inventoryType,
                  currentInventoryValue,
                  key
                )
              }
            />
          </>
        ) : (
          <>
            <span>Enter value to add/subtract by </span>
            <InputNumber
              type='number'
              defaultValue={0}
              className='inventory-input'
              onChange={(value) =>
                this.setNewInventoryValue(
                  value,
                  id,
                  inventoryType,
                  currentInventoryValue,
                  key
                )
              }
            />
          </>
        )}
        <br />
        {!isUndefined(this.state[id + '_' + key]) ? (
          <>
            <span> Current Value </span>
            <Typography.Text className='inventory-value-text' delete>
              {currentInventoryValue}
            </Typography.Text>
            <Typography.Text className='inventory-value-text'>
              {this.state[id + '_' + key]}
            </Typography.Text>
          </>
        ) : (
          <>
            <span> Current Value </span>
            <Typography.Text className='inventory-value-text'>
              {currentInventoryValue}
            </Typography.Text>
          </>
        )}
      </>
    );
  };

  setNewInventoryValue = (value, id, inventoryType, existingValue, key) => {
    let newInventoryValue = 0;
    if (
      isUndefined(this.state[id + '_currentAction']) ||
      this.state[id + '_currentAction'] ===
        StringConstants.ModellingActions.REPLACE
    )
      newInventoryValue = value;
    else if (
      this.state[id + '_currentAction'] ===
      StringConstants.ModellingActions.ADD_PERCENTAGE
    )
      newInventoryValue = existingValue + (value / 100) * existingValue;
    else newInventoryValue = existingValue + value;

    if (
      (inventoryType === 'incomingInventory' && newInventoryValue < 0) ||
      (inventoryType === 'outgoingInventory' && newInventoryValue > 0)
    ) {
      this.setState({ [id + '_' + key]: undefined, disableSubmit: true });
      return;
    }

    if (newInventoryValue === existingValue)
      this.setState({ [id + '_' + key]: undefined, disableSubmit: true }, () =>
        this.setCurrentChange(
          newInventoryValue,
          value,
          id,
          inventoryType,
          key,
          false
        )
      );
    else
      this.setState(
        { [id + '_' + key]: newInventoryValue, disableSubmit: false },
        () =>
          this.setCurrentChange(
            newInventoryValue,
            value,
            id,
            inventoryType,
            key,
            true
          )
      );
  };

  onActionChange = (elt, value) => {
    this.setState({
      [elt.itemNumber +
      '_' +
      elt.code +
      '_' +
      elt.weekTitle +
      '_currentAction']: value,
    });
  };

  setCurrentChange = (newValue, value, id, inventoryType, key, hasChange) => {
    if (!hasChange) {
      this.setState({ [id + '_currentChange']: undefined });
      return;
    }

    let changeObj = {
      operationType: isUndefined(this.state[id + '_currentAction'])
        ? 'REPLACE'
        : Object.keys(StringConstants.ModellingActions).filter(
            (key) =>
              StringConstants.ModellingActions[key] ===
              this.state[id + '_currentAction']
          )[0],
      inputValue: value,
      fieldName: getKeyString(key),
      newValue,
    };
    this.setState({ [id + '_currentChange']: changeObj });
  };

  render() {
    const { disableSubmit } = this.state;
    const { visible, data } = this.props;
    return (
      <Modal
        visible={visible}
        title={'Edit ' + startCase(data.key)}
        onOk={() =>
          this.props.updateData(
            !isEmpty(this.state)
              ? this.state[
                  data.itemNumber +
                    '_' +
                    data.code +
                    '_' +
                    data.weekTitle +
                    '_currentChange'
                ]
              : null,
            data
          )
        }
        onCancel={() => this.props.closeModal()}
        okButtonProps={{ disabled: disableSubmit }}
      >
        <Typography.Title level={5}>
          {data.itemNumber + ' - ' + data.code + ' - ' + data.weekTitle}
        </Typography.Title>
        {this.renderModellingActions(data)}
      </Modal>
    );
  }
}

export default EditLeverModal;
