import { Button, Col, Empty, Row, Select, Tooltip, Typography } from 'antd';
import {
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';

import ApiUtils from '../utils/ApiUtils';
import BoardCreationModal from '../components/board/BoardCreationModal';
import BoardPreview from '../components/board/BoardPreview';
import BoardService from '../services/BoardService';
import LoadingComponent from '../components/LoadingComponent';
import React from 'react';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../components/SecureComponent';
import StringConstants from '../constants/StringConstants';
import UrlGenerator from '../api/UrlGenerator';
import { connect } from 'react-redux';
import { getFormattedFullDate } from '../utils/DateUtils';
import { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import BoardCreationResponse from '../components/board/BoardCreationResponse';

const { Option } = Select;

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      boards: [],
      currentSort: 'name',
      order: 'asc',
      boardCreationModalVisible: false,
      createdBoardId: '',
      boardCreationResponseModalVisible: false,
      creatingBoard: false,
      navForecastInfo: undefined,
    };
  }

  componentDidMount() {
    this.fetchAllBoards();
  }

  fetchAllBoards = async () => {
    this.setState({ loading: true });
    BoardService.getAllBoards()
      .then((res) => {
        this.setState({ boards: res.data }, () => {
          BoardService.getNavForecastInfo()
            .then((response) => {
              this.setState({ navForecastInfo: response.data, loading: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        });
      })
      .catch((err) => {
        this.setState({ loading: false, boards: [] });
      });
  };

  deleteBoard = async (boardId) => {
    this.setState({ loading: true });
    BoardService.deleteBoard(boardId)
      .then((res) => {
        this.setState({ loading: false }, () => this.fetchAllBoards());
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getSortedBoards = () => {
    const { boards, currentSort, order } = this.state;
    return orderBy(boards, currentSort, order);
  };

  setLoadingText = () => {
    this.setState({
      loadingText:
        StringConstants.LoadingTexts[
          parseInt(Math.random() * StringConstants.LoadingTexts.length)
        ],
    });
  };

  onBoardCreate = (values) => {
    this.setState({
        creatingBoard: true,
        loadingText: 'Creating board ...'
    });
    BoardService.createBoard(values)
        .then((response) => {
            this.createdBoardId = response.data.id;
            this.setState({
                creatingBoard: false,
                boardCreationResponseModalVisible: true,
                boardCreationModalVisible: false
            }, () => this.fetchAllBoards());
            this.onBoardCreateCopyNavForecast(this.createdBoardId)
        }, )
        .catch((err) => {
            let errorMessage = ApiUtils.getErrorMessage(err);
            toast.error('Failed to create board - ' + errorMessage);
            this.setState({
                creatingBoard: false
            });
        });
};
  
   
  onBoardCreateCopyNavForecast = (boardId) => {
    try {
        BoardService.copyForecastDataToBoard(boardId);
    } catch (err) {
        console.log(err);
    }
};

  render() {
    const {
      loading,
      boards,
      currentSort,
      order,
      boardCreationModalVisible,
      boardCreationResponseModalVisible,
      creatingBoard,
      loadingText,
      navForecastInfo,
    } = this.state;
    const { user } = this.props;
    if (loading) return <LoadingComponent title='Fetching all boards ...' />;
    if (creatingBoard) return <LoadingComponent title={loadingText} />;

    return (
      <>
        <Row justify='space-between'>
          <Col span={6}>
            <Typography.Title level={5}>
              Welcome {user && user.name} !
            </Typography.Title>
          </Col>
          {boards && boards.length > 0 && (
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button
                type='primary'
                onClick={() => {
                  this.setState({ boardCreationModalVisible: true });
                }}
              >
                Create a Board
                <PlusOutlined />
              </Button>
            </Col>
          )}
        </Row>
        <Row justify='space-between' align='center'>
          <Col span={12} className='nav-forecast-info'>
            Last synced at -{' '}
            <Typography.Title level={5}>
              {navForecastInfo &&
                getFormattedFullDate(navForecastInfo.navLastPulledAt)}
            </Typography.Title>
          </Col>
          <Col span={4} className='board-sort-section'>
            <span> Sort By: </span>
            <Select
              value={currentSort}
              onSelect={(value) => {
                if (currentSort === value)
                  this.setState({
                    order: order === 'asc' ? 'desc' : 'asc',
                  });
              }}
              onChange={(value) => {
                this.setState((prevState) => {
                  return {
                    currentSort: value,
                    order: prevState.currentSort !== value ? 'asc' : 'desc',
                  };
                });
              }}
            >
              {StringConstants.BoardSortOptions.map((option) => {
                return (
                  <Option key={option.field} value={option.field}>
                    {currentSort === option.field ? (
                      order === 'asc' ? (
                        <Tooltip title='Click to sort by descending'>
                          {option.title}
                          <SortAscendingOutlined className='board-sort-icon' />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Click to sort by ascending'>
                          {option.title}
                          <SortDescendingOutlined className='board-sort-icon' />
                        </Tooltip>
                      )
                    ) : (
                      option.title
                    )}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <hr />
        <Row justify={boards && boards.length === 0 ? 'center' : 'start'}>
          {boards && boards.length === 0 ? (
            <Col className='boards-section'>
              <Empty
                description={
                  <>
                    <p> You have not created any boards yet !</p>
                    <Button
                      type='primary'
                      onClick={() => {
                        this.setState({ boardCreationModalVisible: true });
                      }}
                    >
                      Create a Board
                      <PlusOutlined />
                    </Button>
                  </>
                }
              />
            </Col>
          ) : (
            this.getSortedBoards().map((board) => {
              return (
                <Col key={board.id} lg={5} xs={24}>
                  <BoardPreview
                    data={board}
                    deleteBoard={() => this.deleteBoard(board.id)}
                  />
                </Col>
              );
            })
          )}
        </Row>
        {boardCreationModalVisible && (
          <BoardCreationModal
            visible={boardCreationModalVisible}
            onSubmit={(values) => this.onBoardCreate(values)}
            onCancel={() => this.setState({ boardCreationModalVisible: false })}
          />
        )}
        {boardCreationResponseModalVisible && (
          <BoardCreationResponse
            visible={boardCreationResponseModalVisible}
            onCancel={() => this.setState({ boardCreationResponseModalVisible: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(SecureComponent(Homepage)));
