const { default: RouteConstants } = require('./RouteConstants');

const BreadcrumbConstants = {
  [[RouteConstants.VIEW_BOARD]]: [
    {
      text: ':id',
    },
  ],
  [[RouteConstants.BOARD_CHANGES]]: [
    {
      text: ':id',
      route: RouteConstants.VIEW_BOARD,
    },
    {
      text: 'Changes',
    },
  ],
  [[RouteConstants.ALL_USERS]]: [
    {
      text: 'Users',
    },
  ],
};

export default BreadcrumbConstants;
