import auth0 from 'auth0-js';

const clientID = process.env.REACT_APP_AUTH0_CLIENTID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

function webAuth(clientID, domain) {
  return new auth0.WebAuth({
    clientID: clientID,
    domain: domain,
  });
}

function login(redirectTo) {
  let redirectUri = process.env.REACT_APP_REDIRECT_URL;
  if (redirectTo) redirectUri += '?redirect=' + redirectTo;
  const options = {
    responseType: 'token id_token',
    redirectUri: redirectUri,
  };

  return webAuth(clientID, domain).authorize(options);
}

function parseHash(cb) {
  return webAuth(clientID, domain).parseHash(cb);
}

function logout(signOutOfIdp) {
  return webAuth(clientID, domain).logout({
    returnTo: process.env.REACT_APP_LOGOUT_RETURN_URL,
    federated: signOutOfIdp,
  });
}

export { login, parseHash, logout };
