import { Route, Switch } from 'react-router-dom';

import BoardChangeLogs from '../board/BoardChangeLogs';
import BoardPage from '../board/BoardPage';
import Homepage from '../Homepage';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import UsersPage from '../user/UsersPage';

const InternalRouter = () => {
  return (
    <Switch>
      <Route path={RouteConstants.HOME} exact component={Homepage} />
      <Route path={RouteConstants.VIEW_BOARD} exact component={BoardPage} />
      <Route
        path={RouteConstants.BOARD_CHANGES}
        exact
        component={BoardChangeLogs}
      />
      <Route path={RouteConstants.ALL_USERS} component={UsersPage}></Route>
    </Switch>
  );
};

export default InternalRouter;
