import './BoardPage.css';

import { Button, Card, Col, Divider, Row, Space, Spin, Typography } from 'antd';
import {
  cloneDeep,
  find,
  findIndex,
  isEmpty,
  isEqual,
  orderBy,
  sortBy,
  startCase,
  uniq,
} from 'lodash';
import { getFormattedDate, getFormattedFullDate } from '../../utils/DateUtils';

import ApiUtils from '../../utils/ApiUtils';
import BoardHeader from '../../components/board/BoardHeader';
import BoardService from '../../services/BoardService';
import ChangesLogComponent from '../../components/modellingChanges/ChangesLogComponent';
import ChartComponent from '../../components/chart/ChartComponent';
import { DownloadOutlined } from '@ant-design/icons';
import FilterComponent from '../../components/chart/FilterComponent';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import SecureComponent from '../../components/SecureComponent';
import TableComponent from '../../components/table/TableComponent';
import UrlGenerator from '../../api/UrlGenerator';
import { getChangeLogObjectForExtendForecast } from '../../utils/DisplayUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class Boardpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshChart: false,
      originalData: undefined,
      boardData: undefined,
      chartData: undefined,
      changes: [],
      renderChangeLog: false,
      weeksEditable: true,
      changeLogs: [],
      changesCount: 0,
    };
  }

  componentDidMount = async () => {
    let fetchSpecs = true;
    await this.fetchBoardData(fetchSpecs);

    let prefilledFilters = UrlGenerator.getQueryParamsFromUrl(
      this.props.location.search
    );

    this.setState({ prefilledFilters });
  };

  downloadExcel = (data, fileName) => {
    const { selectedLocation, selectedForecast } = this.state;
    data = data.filter(
      (elt) =>
        elt.code === selectedLocation && elt.forecastName === selectedForecast
    );
    data = orderBy(data, 'id', 'asc');
    var arrData = typeof data != 'object' ? JSON.parse(data) : data;

    var CSV = '';

    var headerRow = '';

    for (let index in arrData[0]) {
      if (index !== 'changeLogs' && index !== 'updatedAt' && index !== 'id') {
        headerRow += startCase(index) + ',';
      }
    }

    headerRow = headerRow.slice(0, -1);

    CSV += headerRow + '\r\n';

    for (let i = 0; i < arrData.length; i++) {
      var row = '';

      for (let index in arrData[i]) {
        if (index === 'period')
          row += '"' + getFormattedDate(arrData[i][index]) + '",';
        else if (
          index !== 'changeLogs' &&
          index !== 'updatedAt' &&
          index !== 'id'
        )
          row +=
            '"' + (arrData[i][index] === null ? '' : arrData[i][index]) + '",';
      }

      row.slice(0, row.length - 1);

      CSV += row + '\r\n';
    }

    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    var link = document.createElement('a');
    link.href = uri;

    link.style = 'visibility:hidden';
    link.download = fileName + '.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  fetchBoardData = async (fetchSpecs) => {
    const { id } = this.props.match.params;
    BoardService.getBoard(id)
      .then((res) => {
        let boardMetaData = cloneDeep(res.data);
        this.setState(
          {
            boardMetaData,
          },
          async () => {
            if (fetchSpecs) {
              let specifications = await BoardService.getSpecifications(id);
              this.setState(
                {
                  fins: orderBy(specifications.data, 'itemDescription'),
                },
                async () => {
                  let changeLogs = await BoardService.fetchChangeLog(id);
                  this.setState({
                    changeLogs: changeLogs.data,
                    changesCount: changeLogs.data.length,
                    loading: false,
                  });
                }
              );
            } else this.setState({ loading: false });
          }
        );
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to fetch board data - ' + errorMessage);
        this.setState({ loading: false });
      });
  };

  getBoardForecast = async (fin) => {
    const { id } = this.props.match.params;
    let boardForecastRes = await BoardService.getBoardForecast(id, {
      itemNumber: fin,
    });

    let data = boardForecastRes.data;

    this.setState({
      originalData: data,
      boardData: data,
      chartData: data,
      locations: uniq(data.boardForecast.map((elt) => elt.code)).sort(
        (a, b) => {
          if (a === 'ALL_LOC' && b !== 'ALL_LOC') return -1;
          else return a.localeCompare(b);
        }
      ),
    });
  };

  fetchBoardDataFromState = (fin, location, forecast) => {
    this.setState(
      {
        renderChartAndTable: false,
        renderChangeLog: false,
        renderingChartAndTable: true,
      },
      async () => {
        const { id } = this.props.match.params;
        let boardForecastRes = await BoardService.getBoardForecast(id, {
          itemNumber: fin,
        });

        let data = boardForecastRes.data;

        let forecastNames = uniq(
          data.boardForecast.map((elt) => elt.forecastName)
        ).sort((a, b) => {
          if (a === 'No Forecast' && b !== 'No Forecast') return -1;
          else return a.localeCompare(b);
        });

        this.setState(
          {
            originalData: data,
            boardData: data,
            chartData: data,
            locations: uniq(data.boardForecast.map((elt) => elt.code)).sort(
              (a, b) => {
                if (a === 'ALL_LOC' && b !== 'ALL_LOC') return -1;
                else return a.localeCompare(b);
              }
            ),
            forecastNames,
            selectedFin: find(this.state.fins, function (o) {
              return o.itemNumber === fin;
            }),
            selectedLocation: location,
            selectedForecast: forecast,
          },
          () =>
            setTimeout(() => {
              this.setState(
                {
                  renderChartAndTable: true,
                  renderChangeLog: true,
                  renderingChartAndTable: false,
                },
                () => {
                  this.chartRef.renderChart(fin, location, forecast, data);
                  this.inventoryTableRef.renderTable(
                    fin,
                    location,
                    forecast,
                    data
                  );
                }
              );
            }, 100)
        );
      }
    );
  };

  selectFin = async (value) => {
    this.setState({
      loadingForecast: true,
      renderChartAndTable: false,
      renderChangeLog: false,
      selectedFin: find(this.state.fins, function (o) {
        return o.itemNumber === value;
      }),
    });
    await this.getBoardForecast(value);
    this.setState({ loadingForecast: false });
  };

  selectLocation = (fin, value) => {
    this.setState({
      filteringLocation: true,
      renderChartAndTable: false,
      renderChangeLog: false,
      selectedLocation: value,
    });
    let boardData = { ...this.state.boardData };
    let originalData = { ...this.state.originalData };

    boardData.boardForecast = originalData.boardForecast.filter(
      (elt) => elt.itemNumber === fin && elt.code === value
    );

    let forecastNames = uniq(
      boardData.boardForecast.map((elt) => elt.forecastName)
    ).sort((a, b) => {
      if (a === 'No Forecast' && b !== 'No Forecast') return -1;
      else return a.localeCompare(b);
    });

    this.setState(
      {
        boardData,
        forecastNames,
        filteringLocation: false,
        renderingChartAndTable: true,
        weeksEditable: value !== 'ALL_LOC',
      },
      () => {
        setTimeout(() => {
          if (forecastNames.length === 1) {
            this.setState(
              {
                renderChartAndTable: true,
                renderChangeLog: true,
                renderingChartAndTable: false,
                selectedForecast: forecastNames[0],
              },
              () => {
                this.chartRef.renderChart(
                  fin,
                  value,
                  forecastNames[0],
                  originalData
                );
                this.inventoryTableRef.renderTable(
                  fin,
                  value,
                  forecastNames[0],
                  originalData
                );
                window.history.pushState(
                  null,
                  null,
                  UrlGenerator.addQueryParamsToUrl(
                    UrlGenerator.getUiUrlWithPathParams(
                      RouteConstants.VIEW_BOARD,
                      { id: boardData.id }
                    ),
                    {
                      fin: fin,
                      location: value,
                      forecast: forecastNames[0],
                    }
                  )
                );
              }
            );
          } else this.setState({ renderingChartAndTable: false });
        }, 100);
      }
    );
  };

  getInventoryTableRef = () => {
    return this.inventoryTableRef;
  };

  getChanges = (boardData) => {
    let changedWeeks = sortBy(
      boardData.boardForecast.filter(
        (forecastWeek) => forecastWeek.changeLogs.length > 0
      ),
      'id'
    );

    const { changeLogs } = this.state;
    let changesCount = this.state.changesCount;

    let currentChanges = [...changedWeeks].map((elt) => {
      let newElt = { ...elt };
      delete newElt['weekData'];
      delete newElt['changes'];
      delete newElt['weekPeriod'];
      delete newElt['weekTitle'];
      return newElt;
    });

    currentChanges = currentChanges.filter(
      (elt) =>
        findIndex(changeLogs, function (o) {
          return o.id === elt.id;
        }) === -1
    );

    changesCount =
      !isEqual(currentChanges, sortBy(changeLogs, 'id')) &&
      !isEmpty(currentChanges)
        ? changesCount + currentChanges.length
        : changeLogs.length;

    //to group the changedLogs data for extend forecast
    getChangeLogObjectForExtendForecast(changedWeeks);

    this.setState({
      changes: changedWeeks,
      changesCount,
      renderChangeLog: true,
    });
  };

  render() {
    const {
      loading,
      refreshChart,
      boardData,
      boardMetaData,
      originalData,
      chartData,
      fins,
      locations,
      forecastNames,
      loadingForecast,
      filteringLocation,
      renderChartAndTable,
      renderingChartAndTable,
      changes,
      renderChangeLog,
      weeksEditable,
      selectedFin,
      selectedLocation,
      selectedForecast,
      changeLogs,
      changesCount,
      prefilledFilters,
    } = this.state;

    if (loading) return <LoadingComponent title='Fetching board data ....' />;

    return (
      <>
        <Row className='full-width-row' style={{ marginTop: 15 }}>
          {loadingForecast && (
            <>
              <div className='rendering-progress'></div>
              <Spin
                className='spinner'
                tip='Loading Forecast ...'
                size='large'
              />
            </>
          )}
          {renderingChartAndTable && (
            <>
              <div className='rendering-progress'></div>
              <Spin
                className='spinner'
                tip='Rendering chart and table ...'
                size='large'
              />
            </>
          )}
          <Col xs={24} lg={12}>
            <Typography.Title level={5} style={{ fontSize: 15 }}>
              {'Synced at : ' +
                getFormattedFullDate(
                  boardMetaData && boardMetaData.navDataPulledAt
                )}
            </Typography.Title>
            {changesCount > 0 ? (
              <Space align='center'>
                <Typography.Text>
                  This board has changes in <b>{changesCount}</b> weeks{' '}
                  {/* <Tooltip title={'Grouped by item, location and forecast'}>
                    <sup>
                      {' '}
                      <InfoCircleOutlined />{' '}
                    </sup>
                  </Tooltip> */}
                </Typography.Text>
                <Link
                  to={{
                    pathname: UrlGenerator.getUiUrlWithPathParams(
                      RouteConstants.BOARD_CHANGES,
                      { id: boardMetaData && boardMetaData.id }
                    ),
                    changeLogs: changeLogs,
                  }}
                >
                  <Button type='link'>View changes</Button>
                </Link>
              </Space>
            ) : (
              <Typography.Text>This board has no changes yet</Typography.Text>
            )}
          </Col>
          <Col xs={24} lg={12} className='save-board-section'>
            <BoardHeader
              boardMetaData={boardMetaData}
              getInventoryTableRef={this.getInventoryTableRef}
              fetchBoardData={(fetchSpecs) => this.fetchBoardData(fetchSpecs)}
            />
            <Button
              type='link'
              icon={<DownloadOutlined />}
              onClick={() =>
                this.downloadExcel(
                  boardData.boardForecast,
                  'Board #' + boardMetaData.id
                )
              }
              disabled={!renderChartAndTable}
            >
              {' '}
              Export{' '}
            </Button>
          </Col>
          <Col xs={24}></Col>
          <Col span={24}>
            <FilterComponent
              prefilledFilters={prefilledFilters}
              fins={fins}
              locations={locations}
              forecastNames={forecastNames}
              loadingForecast={loadingForecast}
              filteringLocation={filteringLocation}
              selectFin={(value) => this.selectFin(value)}
              selectLocation={(fin, value) => this.selectLocation(fin, value)}
              selectForecast={(fin, location, forecastName, doNotTransform) => {
                this.setState(
                  {
                    renderChartAndTable: true,
                    renderChangeLog: true,
                    renderingChartAndTable: true,
                    selectedForecast: forecastName,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({ renderingChartAndTable: false }, () => {
                        this.chartRef.renderChart(
                          fin,
                          location,
                          forecastName,
                          originalData
                        );
                        this.inventoryTableRef.renderTable(
                          fin,
                          location,
                          forecastName,
                          originalData,
                          doNotTransform
                        );
                        window.history.pushState(
                          null,
                          null,
                          UrlGenerator.addQueryParamsToUrl(
                            UrlGenerator.getUiUrlWithPathParams(
                              RouteConstants.VIEW_BOARD,
                              { id: boardMetaData.id }
                            ),
                            {
                              fin: selectedFin.itemNumber,
                              location: selectedLocation,
                              forecast: forecastName,
                            }
                          )
                        );
                      });
                    }, 100);
                  }
                );
              }}
              fetchBoardDataFromState={(fin, location, forecast) =>
                this.fetchBoardDataFromState(fin, location, forecast)
              }
            />
          </Col>
          <Col span={24}>
            {renderChartAndTable ? (
              <>
                <Card>
                  <ChartComponent
                    ref={(ref) => (this.chartRef = ref)}
                    refresh={refreshChart}
                    boardData={chartData}
                  />
                </Card>
                <Divider> Full Chain Inventory Table </Divider>
                <Card>
                  <TableComponent
                    ref={(ref) => (this.inventoryTableRef = ref)}
                    originalData={originalData}
                    boardData={boardData}
                    refreshChart={(boardData) =>
                      this.setState({
                        refreshChart: !this.state.refreshChart,
                        chartData: boardData,
                      })
                    }
                    getChanges={(boardData) => this.getChanges(boardData)}
                    weeksEditable={weeksEditable}
                    selectedLocation={selectedLocation}
                    selectedForecast={selectedForecast}
                    locations={locations}
                  />
                </Card>
              </>
            ) : (
              <span className='select-prompt-text'>
                Please choose a FIN, Location and Forecast to get started
              </span>
            )}
          </Col>
          {renderChangeLog && selectedLocation !== 'ALL_LOC' && (
            <>
              <Divider> Change Log </Divider>
              <Col span={24}>
                <ChangesLogComponent
                  changes={changes}
                  boardId={boardMetaData && boardMetaData.id}
                  selectedFin={selectedFin}
                  selectedLocation={selectedLocation}
                  selectedForecast={selectedForecast}
                />
              </Col>
            </>
          )}
        </Row>
      </>
    );
  }
}

export default withRouter(SecureComponent(Boardpage));
