import { Alert, Button, Col, Row } from 'antd';
import React, { Component } from 'react';

import { LogoutOutlined } from '@ant-design/icons';
import { deleteToken } from '../utils/auth/auth';
import { logout } from '../utils/auth/auth0';
import { withRouter } from 'react-router';

class NoAccessComponent extends Component {
  handleLogout = () => {
    deleteToken();
    sessionStorage.removeItem('state');
    logout();
  };
  render() {
    return (
      <Row align='center' justify='center'>
        <Col xs={24}>
          <Alert
            message='Not Authorized'
            description="Looks like you are trying to access something that's restricted. Please contact support for details."
            type='error'
            showIcon
            style={{ marginBottom: 24 }}
          />
        </Col>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <Button
            type='danger'
            icon={<LogoutOutlined />}
            onClick={() => this.handleLogout()}
          >
            Logout
          </Button>
        </Col>
      </Row>
    );
  }
}

export default withRouter(NoAccessComponent);
