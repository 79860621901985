import { Button, Card, List, Spin, Typography } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';

import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import UrlGenerator from '../../api/UrlGenerator';
import { downloadExcel } from '../../utils/DownloadUtils';
import { getChangeElement } from '../../utils/DisplayUtils';
import { orderBy } from 'lodash';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class ChangesLogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: props.changes,
      updatingChangeLog: false,
      selectedFin: props.selectedFin,
      selectedLocation: props.selectedLocation,
      selectedForecast: props.selectedForecast,
    };
  }

  viewAllChangeLog = () => {
    const { boardId } = this.props;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.BOARD_CHANGES, {
        id: boardId,
      })
    );
  };

  componentDidUpdate() {
    if (this.state.changes !== this.props.changes) {
      this.setState(
        { updatingChangeLog: true, changes: this.props.changes },
        () => {
          setTimeout(() => {
            this.setState({
              updatingChangeLog: false,
            });
          }, 200);
        }
      );
    }
  }

  render() {
    const { id } = this.props.match.params;
    const {
      changes,
      updatingChangeLog,
      selectedFin,
      selectedLocation,
      selectedForecast,
    } = this.state;

    return (
      <>
        {updatingChangeLog && (
          <>
            <div className='rendering-progress'></div>
            <Spin
              className='spinner'
              tip='Updating change log ...'
              size='large'
            />
          </>
        )}
        <Card
          extra={
            <div style={{ textAlign: 'right' }}>
              <Typography.Title level={5}>
                <span> Showing for </span>
                <span style={{ fontWeight: 'bold' }}>
                  {selectedFin.itemDescription +
                    ' (' +
                    selectedFin.itemNumber +
                    ')'}
                </span>
                <br />
                <span style={{ fontWeight: 500 }}>
                  {selectedLocation + ' | ' + selectedForecast}
                </span>
              </Typography.Title>
              <Button
                type='link'
                icon={<EyeOutlined />}
                onClick={() => this.viewAllChangeLog()}
              >
                View All
              </Button>
              <Button
                type='link'
                icon={<DownloadOutlined />}
                onClick={() => downloadExcel(changes, id, false)}
                disabled={changes.length === 0}
              >
                Export Changes
              </Button>
            </div>
          }
        >
          <List
            dataSource={orderBy(changes, 'period', 'asc')}
            renderItem={(weekChange) => {
              return getChangeElement(weekChange);
            }}
          ></List>
        </Card>
      </>
    );
  }
}

export default withRouter(ChangesLogComponent);
