const getErrorMessage = (
  errorResponse,
  defaultErrorMsg = 'Failed to process request'
) => {
  let errorMessage = defaultErrorMsg;
  if (errorResponse.data && errorResponse.data.message) {
    errorMessage = errorResponse.data.message;
  }
  return errorMessage;
};

const getForecastExtendChangeRequest = (inputValue) => {
  return {
    operationType: "REPLACE",
    inputValue,
    fieldName: "FORECAST"
  }
}

const ApiUtils = {
  getErrorMessage,
  getForecastExtendChangeRequest
};

export default ApiUtils;
