import { Button, Result } from 'antd';
import React, { Component } from 'react';

import StringConstants from '../constants/StringConstants';
import { connect } from 'react-redux';
import { deleteToken } from '../utils/auth/auth';
import { logout } from '../utils/auth/auth0';
import RouteConstants from '../constants/RouteConstants';

class ErrorComponent extends Component {
	handleLogout = () => {
		deleteToken();
		sessionStorage.removeItem('state');
		let signOutOfIdp = true;
		logout(signOutOfIdp);
	};

	render() {
		if (this.props.route?.errorType === StringConstants.UnauthorizedError) {
			return (
				<Result
					status='403'
					title='403'
					subTitle='Sorry, you are not authorized to access this page. Please contact support'
					extra={
						<Button type='primary' onClick={() => this.handleLogout()}>
							Login with a different account{' '}
						</Button>
					}
				/>
			);
		} else if (this.props.match.path === RouteConstants.NOTAGROUPMEMBER) {
			return (
				<Result
					status='403'
					title='403'
					subTitle='Sorry, you are not authorized group member to access this page. Please contact support'
				/>
			);
		}
		return <Result status='500' title='Something went wrong' />;
	}
}

const mapStateToProps = (state) => {
	return {
		route: state.route,
	};
};

export default connect(mapStateToProps, null)(ErrorComponent);
