const StringConstants = {
  POLevels: [
    {
      level: 'Full Chain',
      wosField: 'wos',
      inventoryField: 'suggestedProjectedInventory',
      // color: '#52c41a',
    },
    {
      level: 'Excluding Planned Orders',
      wosField: 'wosWoAm',
      inventoryField: 'forecastedProjectedInventory',
      // color: '#fa541c',
    },
    {
      level: 'Confirmed/On Water',
      wosField: 'wosConfirmed',
      inventoryField: 'confirmedProjectedInventory',
      // color: '#13c2c2',
    },
    {
      level: 'Warehouse only',
      wosField: 'wosInWhse',
      inventoryField: 'forecasedProjectedInventory  - scheduledReceipt',
      // color: '#fadb14',
    },
  ],
  AnalysisTabs: {
    WoS: 'WoS',
    Inventory: 'Inventory',
  },
  ModellingActions: {
    REPLACE: 'Replace',
    ADD_PERCENTAGE: '+/- by %',
    ADD_VALUE: '+/- by value',
  },
  BoardSortOptions: [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
    },
  ],
  InventoryFields: [
    {
      type: 'incomingInventory',
      fields: [
        {
          name: 'Planned Orders',
          key: 'actionMessageQty',
        },
        {
          name: 'Purchase Orders',
          key: 'scheduledReceipt',
        },
        {
          name: 'Production Orders',
          key: 'scheduledProduction',
        },
        {
          name: 'Transfers In',
          key: 'scheduledTransferIn',
        },
      ],
    },
    {
      type: 'outgoingInventory',
      fields: [
        {
          name: 'Forecast',
          key: 'forecast',
        },
        {
          name: 'Transfers Out',
          key: 'scheduledTransferOut',
        },
      ],
    },
  ],
  COOKIES: {
    AUTH_TOKEN: 'token',
    AUTH_REFRESH_TOKEN: 'refreshToken',
    EXPIRY_REPORT_SCREEN: 'expiryReportFilters',
  },
  LoadingTexts: [
    'Fishes have tastebuds all over their body and can taste without even opening their mouth !',
    'Did you know fish can make sounds? The loudest fish is the croaker. You might have never heard’ a fish and they don’t have any vocal cords as well. But they use other parts to boom, grunt, hiss, creak, wail and shriek to convey their messages !',
    'Fish can drown too! This can happen if there is not enough oxygen in the water',
    'The Spotted Climbing Perch can gulp’ oxygen from the air and walk’ on land using its pectoral fins if it needs to look for water !',
    'Fish can get sunburned even underwater! However, this is rare and can only take place when there is something about their environment that doesn’t them to sink into deeper water to get to a shelter',
    'The parrotfish produces a kind of mucus that covers them completely for protection !',
    'Fish have a special sense organ much like a radar called the lateral line. This allows them to move around dark or murky waters !',
    'Most fishes have darker skin colour on top and lighter on the bottom; they are counter-shaded so that they are not easily visible in the water !',
    'Certain species of fish like sharks need to always keep swimming as they do not possess an air bladder that could keep them afloat !',
    'The Antarctic icefish have antifreeze in their blood and can live permanently at -1°C !',
    'Fish cannot chew and if they did try, they would probably suffocate and die as it interferes with water from passing through their gills !',
    'Fish get stressed out just like mammals and birds! They actually have an excellent sense of taste, touch and sight and some can even hear and smell very well',
    'The lungfish can survive on land for several days without water as it possesses both lungs and gills !',
    'The speed of sailfish can easily beat your car; With a speed of 110 km/hr, the Indo-Pacific Sailfish or Istiophorus platypterus is the world’s fastest fish !',
    'The slowest fish is the seahorse. It moves so slowly that you can hardly notice !',
    'Jellyfish and starfish are not fish family creatures though they have fish’ in their name ! To be a fish, an animal needs to have bones, scales and gills, but they don’t have any of these features',
    'The pufferfish carries a toxin 1200 times more poisonous than Cyanide; it is enough to kill 30 people !',
  ],
  UserStatus: { ACTIVE: 'ACTIVE', TO_BE_DELETED: 'TO_BE_DELETED' },
  UserRole: { SUPER_ADMIN: 'SUPER_ADMIN', EDITOR: 'EDITOR' },
  ExcelHeaders: [
    {
      label: 'Item Description (Item Number): ',
      fields: ['itemDescription', 'itemNumber'],
    },
    {
      label: 'Location:',
      fields: ['code'],
    },
    {
      label: 'Forecast: ',
      fields: ['forecastName'],
    },
  ],
  UnauthorizedError: 'UNAUTHORIZED',
};

export default StringConstants;
