import { Modal } from 'antd';

import React from 'react';

class BoardCreationResponse extends React.Component {
  render() {
    const { visible } = this.props;
    return (
      <Modal
        visible={visible}
        title={
        <b>Your board is being generated!</b>}
        onOk={() => this.props.onCancel()}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
      
      <b>We will notify you by email when the board is ready.</b>
          
      
        
      </Modal>
    );
  }
}

export default BoardCreationResponse;
