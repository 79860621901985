export const msalConfig = {
  auth: {
    clientId: '5173bea2-5e66-4ce6-934f-559e9147c7d9',
    authority:
      'https://login.microsoftonline.com/dd57e4e2-8a6c-4d67-9745-c2c76aba2b8f',
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
