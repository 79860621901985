import { Button, Dropdown, Layout, Menu, Typography } from 'antd';
import {
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { isUserActive, isUserSuperAdmin } from '../../helpers/user';

import { Link } from 'react-router-dom';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import SecureComponent from '../SecureComponent';
import { connect } from 'react-redux';
import { deleteToken } from '../../utils/auth/auth';
import { logout } from '../../utils/auth/auth0';
import { MsalContext } from "@azure/msal-react";

const { Header } = Layout;

class HeaderComponent extends React.Component {
  static contextType = MsalContext;
  
  handleLogout = () => {
    deleteToken();
    sessionStorage.removeItem('state');
    const instance = this.context.instance;
    let homeId = instance.getActiveAccount().homeAccountId;
    instance
      .logoutRedirect({
        account: instance.getAccountByHomeId(homeId),
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_RETURN_URL,
      })
      .catch((e) => {
        console.error(e);
      });
    logout();
  };

  actionsMenu = (
    <Menu>
      {isUserSuperAdmin() && (
        <Menu.Item icon={<TeamOutlined />}>
          <Link to={RouteConstants.ALL_USERS}>All Users</Link>
        </Menu.Item>
      )}
      <Menu.Item
        danger
        icon={<LogoutOutlined />}
        onClick={() => this.handleLogout()}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  render() {
    return (
      <Header className='app-header'>
        <div className='nav-center'>
          <Typography.Title>WoS Modelling Tool</Typography.Title>
        </div>
        {isUserActive() && (
          <div className='nav-right'>
            <Dropdown overlay={this.actionsMenu} trigger={['click']}>
              <Button type='link' icon={<SettingOutlined />}></Button>
            </Dropdown>
          </div>
        )}
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(SecureComponent(HeaderComponent));
