import ApiManager from '../api/ApiManager';
import HttpConstants from '../constants/HttpConstants';
import UrlConstants from '../constants/UrlConstants';

const BoardService = {
  getAllBoards: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_BOARDS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  createBoard: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_BOARD.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  copyForecastDataToBoard: (boardId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.COPY_BOARD_FORECAST.USECASE,
      HttpConstants.POST_METHOD,
      { ':id': boardId }
    );
  },
  getBoard: (boardId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_BOARD.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': boardId }
    );
  },
  updateBoard: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_BOARD.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  deleteBoard: (boardId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DELETE_BOARD.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':id': boardId }
    );
  },
  getSpecifications: (boardId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATIONS.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': boardId }
    );
  },
  getLocations: (boardId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_LOCATIONS.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': boardId, ...data }
    );
  },
  getBoardForecast: (boardId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_BOARD_FORECAST.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': boardId, ...data }
    );
  },
  updateBoardForecast: (boardForecastId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_BOARD_FORECAST.USECASE,
      HttpConstants.PATCH_METHOD,
      { ':id': boardForecastId, ...data }
    );
  },
  fetchChangeLog: (boardId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.FETCH_CHANGE_LOG.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': boardId }
    );
  },
  getNavForecastInfo: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_NAV_FORECAST_INFO.USECASE,
      HttpConstants.GET_METHOD
    );
  },
};

export default BoardService;
