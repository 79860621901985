import ApiManager from '../api/ApiManager';
import HttpConstants from '../constants/HttpConstants';
import UrlConstants from '../constants/UrlConstants';

const UserService = {
  getUserProfile: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_USER_PROFILE.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getAllUsers: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_USERS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  createUser: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_USER.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  changeUserRole: (userId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CHANGE_USER_ROLE.USECASE,
      HttpConstants.PATCH_METHOD,
      { ':id': userId, ...data }
    );
  },
  updateUser: (userId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_USER.USECASE,
      HttpConstants.PATCH_METHOD,
      { ':id': userId, ...data }
    );
  },
  removeUser: (userId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REMOVE_USER.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':id': userId }
    );
  },
};

export default UserService;
