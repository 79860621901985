import { Button, Input, Space, Typography } from 'antd';

import ApiUtils from '../../utils/ApiUtils';
import BoardService from '../../services/BoardService';
import React from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class BoardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boardName: props.boardMetaData && props.boardMetaData.name,
      boardDescription: props.boardMetaData && props.boardMetaData.description,
      savingBoard: false,
    };
  }

  updateBoard = () => {
    this.setState({ savingBoard: true });
    const { id } = this.props.match.params;
    const { boardName, boardDescription } = this.state;

    let updateObj = {
      ':id': id,
    };
    updateObj.name = boardName;
    updateObj.description = boardDescription;

    BoardService.updateBoard(updateObj)
      .then((res) => {
        toast.success('Updated board succesfully');
        this.setState({ savingBoard: false }, () => {
          let fetchSpecs = false;
          this.props.fetchBoardData(fetchSpecs);
        });
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to update board - ' + errorMessage);
        this.setState({ savingBoard: false });
      });
  };

  render() {
    const { boardMetaData } = this.props;
    const { boardName, boardDescription, savingBoard } = this.state;
    return (
      <Space>
        <Typography.Title level={5} className='input-label'>
          Name:
        </Typography.Title>
        <Input
          onChange={(event) => this.setState({ boardName: event.target.value })}
          value={boardName}
          placeholder='Enter board name'
        />
        <Typography.Title level={5} className='input-label'>
          Description:
        </Typography.Title>
        <Input
          onChange={(event) =>
            this.setState({ boardDescription: event.target.value })
          }
          value={boardDescription}
          placeholder='Enter board description'
        />
        <Button
          type='primary'
          disabled={
            isEmpty(boardName) ||
            isEmpty(boardDescription) ||
            (boardMetaData.name === boardName &&
              boardMetaData.description === boardDescription)
          }
          onClick={() => this.updateBoard()}
          loading={savingBoard}
        >
          {'Update'}
        </Button>
      </Space>
    );
  }
}

export default withRouter(BoardHeader);
