import './FilterComponent.css';

import { Col, Row, Select } from 'antd';
import { isEmpty, isUndefined } from 'lodash';

import React from 'react';

const { Option } = Select;

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fin: !isEmpty(props.prefilledFilters)
        ? props.prefilledFilters.fin
        : undefined,
      location: !isEmpty(props.prefilledFilters)
        ? props.prefilledFilters.location
        : undefined,
      forecastName: !isEmpty(props.prefilledFilters)
        ? props.prefilledFilters.forecast
        : undefined,
    };

    if (!isEmpty(props.prefilledFilters))
      this.props.fetchBoardDataFromState(
        this.state.fin,
        this.state.location,
        this.state.forecastName
      );
  }

  render() {
    const {
      fins,
      locations,
      forecastNames,
      loadingForecast,
      filteringLocation,
    } = this.props;
    const { fin, location, forecastName } = this.state;

    return (
      <Row className='filters-row' gutter={30}>
        <Col span={8}>
          <span className='filter-title'> {'FIN'} </span>
          <Select
            className='filter-select-100w'
            showSearch={true}
            onChange={(value) =>
              this.setState(
                { fin: value, location: undefined, forecastName: undefined },
                () => this.props.selectFin(value)
              )
            }
            placeholder={'Please choose a FIN'}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={fin}
          >
            {fins.map((elt) => (
              <Option key={elt.itemNumber} value={elt.itemNumber}>
                {elt.itemNumber + ' - ' + elt.itemDescription}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <span className='filter-title'> {'Location'} </span>
          <Select
            disabled={isUndefined(fin) || loadingForecast}
            loading={loadingForecast}
            className='filter-select-100w'
            showSearch={true}
            onChange={(value) =>
              this.setState(
                { location: value, forecastName: undefined },
                () => {
                  this.props.selectLocation(fin, value);
                }
              )
            }
            placeholder={
              isUndefined(fin)
                ? 'Please choose a FIN first'
                : loadingForecast
                ? 'Loading locations for selected FIN'
                : 'Please choose a location'
            }
            value={
              locations && locations.length === 1 && !isUndefined(fin)
                ? locations[0]
                : location
            }
          >
            {locations &&
              locations.map((elt) => (
                <Option key={elt} value={elt}>
                  {elt === 'ALL_LOC' ? 'All Locations' : elt}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={8}>
          <span className='filter-title'> {'Forecast Name'} </span>
          <Select
            disabled={
              isUndefined(fin) || isUndefined(location) || filteringLocation
            }
            loading={filteringLocation}
            className='filter-select-100w'
            showSearch={true}
            onChange={(value) =>
              this.setState({ forecastName: value }, () =>
                this.props.selectForecast(fin, location, value)
              )
            }
            placeholder={
              isUndefined(location)
                ? 'Please choose a FIN and location first'
                : loadingForecast
                ? 'Loading forecasts'
                : 'Please choose a forecast'
            }
            value={
              forecastNames &&
              forecastNames.length === 1 &&
              !isUndefined(location)
                ? forecastNames[0]
                : forecastName
            }
          >
            {forecastNames &&
              forecastNames.map((elt) => (
                <Option key={elt} value={elt}>
                  {elt}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
    );
  }
}

export default FilterComponent;
