import moment from 'moment';

export const getCurrentWeekOfYear = () => {
  var currentdate = new Date();
  var firstJan = new Date(currentdate.getFullYear(), 0, 1);
  var numberOfDays = Math.floor(
    (currentdate - firstJan) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
};

export const getFormattedFullDate = (date) => {
  if (date) return moment(date).format('lll');
  return '-';
};

export const getFormattedDate = (date) => {
  if (date) return moment(date).format('ll');
  return '-';
};
