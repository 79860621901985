import { actionTypes } from '../actions/types';

export default function user(state = {}, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...action.user,
      };
    case actionTypes.FETCH_USER_FAILURE:
      let newState = { ...state };
      delete newState.user;
      return {
        ...newState,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
}
