import { applyMiddleware, createStore } from 'redux';
import { loadState, saveState } from './utils/misc/SessionStorageUtils';
import { pick, throttle } from 'lodash';

import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers';
import rootSaga from './sagas';

export const sagaMiddleware = createSagaMiddleware();

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(sagaMiddleware)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(
  throttle(() => {
    // save only ceratin parts of state
    saveState(pick(store.getState(), ['user']));
  }, 1000)
);

sagaMiddleware.run(rootSaga);

export default store;
