import { Form, Modal, Select } from 'antd';

import React from 'react';
import StringConstants from '../../constants/StringConstants';
import { isUndefined } from 'lodash';
import { renderUserRole } from '../../utils/DisplayUtils';

const { Option } = Select;

class UpdateUserRoleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: undefined,
    };
  }
  render() {
    const { selectedRole } = this.state;
    const { visible, data, updatingRole } = this.props;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
    return (
      <Modal
        visible={visible}
        title={
          <span>
            {'Update Role for '} <b>{data.name}</b>
          </span>
        }
        onOk={() => this.updateRoleForm.submit()}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{
          disabled: isUndefined(selectedRole) || selectedRole === data.role,
          loading: updatingRole,
        }}
        cancelButtonProps={{ disabled: updatingRole }}
      >
        <Form
          {...layout}
          onFinish={(values) => this.props.onSubmit(values, data.id)}
          ref={(ref) => (this.updateRoleForm = ref)}
          initialValues={{ role: data.role }}
          className='mt-30'
        >
          <Form.Item label='Role' name='role' labelAlign='left' hasFeedback>
            <Select
              onChange={(value) => this.setState({ selectedRole: value })}
            >
              {Object.keys(StringConstants.UserRole).map((role) => {
                return (
                  <Option key={role} value={role} defaultValue={data.role}>
                    {renderUserRole(role)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default UpdateUserRoleModal;
