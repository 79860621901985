import { Button, Card, Popconfirm, Space, Table } from 'antd';
import {
  UserAddOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { renderUserRole, renderUserStatus } from '../../utils/DisplayUtils';

import AddUserModal from '../../components/modals/AddUserModal';
import ApiUtils from '../../utils/ApiUtils';
import LoadingComponent from '../../components/LoadingComponent';
import React from 'react';
import SecureComponent from '../../components/SecureComponent';
import StringConstants from '../../constants/StringConstants';
import TableFilterUtils from '../../utils/TableFilterUtils';
import UpdateUserRoleModal from '../../components/modals/UpdateUserRoleModal';
import UserService from '../../services/UserService';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      addUserModalVisible: false,
      updateRoleModalVisible: false,
      selectedUser: undefined,
      creatingUser: false,
      updatingRole: false,
      removingUser: false,
    };
  }

  componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = () => {
    this.setState({ loading: true });
    UserService.getAllUsers()
      .then((response) => {
        this.setState({ loading: false, users: response.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getColumns = (data) => {
    const { updatingRole, removingUser } = this.state;
    return [
      {
        title: 'Name',
        width: 300,
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        render: (record) => {
          return (
            <>
              <span> {record.name} </span>
              {this.props.user.id === record.id && (
                <span>
                  <b> (Current User) </b>
                </span>
              )}
            </>
          );
        },
      },
      {
        title: 'E-Mail',
        dataIndex: 'email',
        width: 300,
      },
      {
        title: 'Role',
        render: (record) => renderUserRole(record.role),
        width: 300,
        filters: TableFilterUtils.getFilterDropdownValues(data, 'role'),
        onFilter: (value, record) => record.role === value,
      },
      {
        title: 'Status',
        render: (record) => renderUserStatus(record.status),
        filters: TableFilterUtils.getFilterDropdownValues(data, 'status'),
        onFilter: (value, record) => record.status === value,
      },
      {
        title: '',
        render: (record) => {
          if (this.props.user.id !== record.id)
            return (
              <Space style={{ textAlign: 'right' }}>
                <Button
                  type='link'
                  icon={<UserSwitchOutlined />}
                  onClick={() =>
                    this.setState({
                      updateRoleModalVisible: true,
                      selectedUser: record,
                    })
                  }
                  loading={updatingRole}
                  disabled={removingUser}
                >
                  Change Role
                </Button>
                <Popconfirm
                  title={
                    <span>
                      {'Are you sure you want to remove '} <b>{record.name}</b>
                    </span>
                  }
                  onConfirm={() => this.removeUser(record.id)}
                  okText='Yes'
                  cancelText='No'
                  loading={updatingRole}
                  disabled={removingUser}
                >
                  <Button type='link' icon={<UserDeleteOutlined />} danger>
                    Remove
                  </Button>
                </Popconfirm>
              </Space>
            );
        },
      },
    ];
  };

  addUser = (values) => {
    this.setState({ creatingUser: true });
    UserService.createUser(values)
      .then((response) => {
        toast.success('Added user successfully !');
        this.setState({ creatingUser: false, addUserModalVisible: false }, () =>
          this.fetchPageData()
        );
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error(errorMessage);
        this.setState({ creatingUser: false, addUserModalVisible: false });
      });
  };

  updateUserRole = (values, id) => {
    this.setState({ updatingRole: true });
    UserService.changeUserRole(id, values)
      .then((response) => {
        toast.success('Updated user role successfully !');
        this.setState(
          { updatingRole: false, updateRoleModalVisible: false },
          () => this.fetchPageData()
        );
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error(errorMessage);
        this.setState({ updatingRole: false, updateRoleModalVisible: false });
      });
  };

  removeUser = (id) => {
    this.setState({ removingUser: true });
    UserService.removeUser(id)
      .then((response) => {
        toast.success('Removed user successfully !');
        this.setState({ removingUser: false }, () => this.fetchPageData());
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error(errorMessage);
        this.setState({ removingUser: false });
      });
  };

  render() {
    const {
      loading,
      users,
      addUserModalVisible,
      updateRoleModalVisible,
      selectedUser,
      creatingUser,
      updatingRole,
      removingUser,
    } = this.state;

    if (loading) return <LoadingComponent title='Fetching all users ...' />;

    return (
      <Card
        title='All Users'
        className='users-table'
        extra={
          <Button
            type='link'
            icon={<UserAddOutlined />}
            onClick={() =>
              this.setState({
                addUserModalVisible: true,
              })
            }
            disabled={updatingRole || creatingUser || removingUser}
          >
            Add User
          </Button>
        }
      >
        <Table
          bordered
          dataSource={users}
          columns={this.getColumns(users)}
          pagination={users.length > 10}
          scroll={{ x: 1000 }}
        ></Table>
        {addUserModalVisible && (
          <AddUserModal
            visible={addUserModalVisible}
            onSubmit={this.addUser}
            onCancel={() => this.setState({ addUserModalVisible: false })}
            creatingUser={creatingUser}
          />
        )}
        {updateRoleModalVisible && (
          <UpdateUserRoleModal
            visible={updateRoleModalVisible}
            data={selectedUser}
            onSubmit={this.updateUserRole}
            onCancel={() => this.setState({ updateRoleModalVisible: false })}
            updatingRole={updatingRole}
          />
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(SecureComponent(UsersPage, [StringConstants.UserRole.SUPER_ADMIN]));
