const RouteConstants = {
  LOGIN: '/',
  AUTH0_LOGIN: '/sso',
  REDIRECT: '/redirect',
  HOME: '/home',
  ERROR: '/error',
  VIEW_BOARD: '/board/:id',
  BOARD_CHANGES: '/board/:id/change-log',
  ALL_USERS: '/users',
  NOTAGROUPMEMBER:'/notagroupmember',
};

export default RouteConstants;
