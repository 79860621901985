import { getField, getReducer } from '.';

import StringConstants from '../constants/StringConstants';
import { isEmpty } from 'lodash';

export const isUserDetailAvailable = () => {
  return !isEmpty(getReducer('user'));
};

export const isUserActive = () => {
  return getField('user', 'status') === StringConstants.UserStatus.ACTIVE;
};

export const isUserSuperAdmin = () => {
  return getField('user', 'role') === StringConstants.UserRole.SUPER_ADMIN;
};
