import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ErrorComponent from './pages/ErrorComponent';
import LoginScreen from './pages/Login';
import MainContent from './pages/MainContent';
import { Provider } from 'react-redux';
import RouteConstants from './constants/RouteConstants';
import { ToastContainer } from 'react-toastify';
import store from './createStore';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/auth/auth0config';
import RedirectComponent from './pages/auth/RedirectComponent';



function App() {
	const msalInstance = new PublicClientApplication(msalConfig);
	return (
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<BrowserRouter>
					<Switch>
						<Route path={RouteConstants.LOGIN} exact component={LoginScreen} />
						<Route
							exact
							path={RouteConstants.REDIRECT}
							component={RedirectComponent}
						/>
						<Route exact path={RouteConstants.ERROR} component={ErrorComponent} />
						<Route exact path={RouteConstants.NOTAGROUPMEMBER} component={ErrorComponent} />
						<Route exact path='*' component={MainContent} />
					</Switch>
				</BrowserRouter>
				<ToastContainer
					position='bottom-left'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
				/>
			</MsalProvider>
		</Provider>
	);
}

export default App;
