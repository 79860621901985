import {
  ArrowDownOutlined,
  BarChartOutlined,
  EditFilled,
  FileSyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  cloneDeep,
  findIndex,
  groupBy,
  isEmpty,
  isNull,
  isUndefined,
  orderBy,
  reverse,
  startCase,
} from 'lodash';
import {
  getFullWeekDisplayText,
  getWoSColorClass,
} from '../../utils/DisplayUtils';

import ApiUtils from '../../utils/ApiUtils';
import BoardService from '../../services/BoardService';
import ChartChangesPopup from '../chart/ChartChangesPopup';
import EditLeverModal from '../EditLeverModal';
import NumberFormat from 'react-number-format';
import React from 'react';
import StringConstants from '../../constants/StringConstants';
import { getFormattedDate } from '../../utils/DateUtils';
import moment from 'moment';
import { sampleData } from '../../sampleData/SampleData';
import { toast } from 'react-toastify';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevBoardData: props.originalData,
      currentBoardData: props.boardData,
      originalData: props.boardData
        ? this.getTransformedData(props.boardData.boardForecast)
        : [],
      boardData: props.boardData
        ? this.getTransformedData(props.boardData.boardForecast)
        : [],
      editLeverModalVisible: false,
      selectedLever: undefined,
      workingChanges: [],
      columns: [],
      hasIncompleteForecast: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.boardData !== this.state.boardData) {
      this.checkIncompleteForecasts();
    }
  }

  getColumns = (data) => {
    let columns = [
      {
        title: 'Week No',
        dataIndex: 'weekTitle',
        width: 90,
      },
      {
        title: 'Period',
        render: (record) =>
          getFormattedDate(record.weekPeriod) +
          ' - ' +
          getFormattedDate(moment(record.weekPeriod).add(7, 'days')),
        width: 200,
      },
      {
        title: 'Gross Requirement',
        render: (record) => (
          <NumberFormat
            thousandSeparator={true}
            displayType='text'
            value={record.grossRequirement}
            renderText={(value) => <span>{value}</span>}
          ></NumberFormat>
        ),

        width: 100,
      },
      this.renderInventoryCellValue(data, 'incomingInventory'),
      this.renderInventoryCellValue(data, 'outgoingInventory'),
      {
        title: 'Suggested Projected Inventory',
        render: (record) => (
          <NumberFormat
            thousandSeparator={true}
            displayType='text'
            value={record.suggestedProjectedInventory}
            renderText={(value) => <span>{value}</span>}
          ></NumberFormat>
        ),
        width: 100,
      },
    ];

    return columns;
  };

  renderInventoryCellValue = (data, inventoryType) => {
    const { weeksEditable } = this.props;
    let subColumns = Object.keys(data[0].weekData[inventoryType]).sort(
      (a, b) =>
        sampleData.columnsOrder[inventoryType][a] -
        sampleData.columnsOrder[inventoryType][b]
    );
    return {
      title: startCase(inventoryType),
      children:
        data && data.length > 0
          ? subColumns.map((key, index) => {
              return {
                title: startCase(key),
                width: 100,
                render: (record) => {
                  let { row } = this.findRowById(
                    this.state.originalData,
                    record.id
                  ); // original row

                  return (
                    <>
                      {row.weekData[inventoryType][key] !==
                      record.weekData[inventoryType][key] ? (
                        <Space>
                          <Typography.Text delete>
                            {record.changes.length > 0 &&
                            inventoryType in record.changes &&
                            key in record.changes[inventoryType]
                              ? record.changes[record.changes.length - 1][
                                  inventoryType
                                ][key]
                              : row.weekData[inventoryType][key]}
                          </Typography.Text>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType='text'
                            value={record.weekData[inventoryType][key]}
                            renderText={(value) => <span>{value}</span>}
                          ></NumberFormat>
                        </Space>
                      ) : (
                        <Typography.Text>
                          <NumberFormat
                            thousandSeparator={true}
                            displayType='text'
                            value={record.weekData[inventoryType][key]}
                            renderText={(value) => <span>{value}</span>}
                          ></NumberFormat>
                        </Typography.Text>
                      )}
                      {weeksEditable &&
                        this.rendorInventoryCellAction(
                          record,
                          inventoryType,
                          key
                        )}
                    </>
                  );
                },
              };
            })
          : [],
    };
  };

  rendorInventoryCellAction = (record, inventoryType, key) => {
    let { row } = this.findRowById(this.state.boardData, record.id);
    const { updatingBoardForecast } = this.state;
    return (
      <Tooltip title={'Click to edit this lever value for this week'}>
        <Button
          type='link'
          icon={<EditFilled />}
          onClick={() => this.openEditLeverModal(row, inventoryType, key)}
          disabled={updatingBoardForecast}
        ></Button>
      </Tooltip>
    );
  };

  openEditLeverModal = (record, inventoryType, key) => {
    this.setState({
      editLeverModalVisible: true,
      selectedLever: { ...record, inventoryType, key },
    });
  };

  findRowById = (data, id) => {
    let rowIndex = findIndex(data, function (o) {
      return o.id === id;
    });

    let row = { ...data[rowIndex] };

    return { rowIndex, row };
  };

  updateData = (changeObj, record) => {
    if (isUndefined(changeObj)) {
      this.setState({ editLeverModalVisible: false });
      return;
    }

    let workingChanges = [...this.state.workingChanges];

    if (isNull(changeObj)) this.setState({ editLeverModalVisible: false });

    let boardData = [...this.state.boardData];
    let { row, rowIndex } = this.findRowById(boardData, record.id);

    row.weekData[record.inventoryType][record.key] = changeObj.newValue;

    let existingChangeIndex = findIndex(workingChanges, function (o) {
      return (
        o.fieldName === changeObj.fieldName && o.boardForecastId === record.id
      );
    });

    let workingChangeObj = { ...changeObj, boardForecastId: record.id };

    if (existingChangeIndex === -1) workingChanges.push(workingChangeObj);
    else workingChanges[existingChangeIndex] = workingChangeObj;

    boardData[rowIndex] = row;

    this.setState({
      boardData,
      columns: [...this.getColumns(boardData)],
      workingChanges,
      editLeverModalVisible: false,
    });
  };

  getWoSesBeforeUpdate = (
    originalData,
    currentLocationCode,
    currentForecastName
  ) => {
    return originalData
      .filter((elt) => {
        return (
          elt.code === currentLocationCode &&
          elt.forecastName === currentForecastName
        );
      })
      .map((elt) => elt.wos);
  };

  getWoSChangeColumns = () => {
    return [
      {
        title: 'Original WoS',
        width: 100,
        render: (record) => {
          setTimeout(() => {
            // Set the parent antd table cell with background color
            let row = document.querySelector(
              '[data-row-key="' + record.id + '"]'
            );
            let originalWosCell = row.childNodes[row.childNodes.length - 2];
            originalWosCell.classList.add(getWoSColorClass(record.wosOriginal));
          }, 100);
          return (
            <span>
              {' '}
              {record.wosOriginal >= 104 ? 'Error' : record.wosOriginal}{' '}
            </span>
          );
        },
      },
      {
        title: 'New WoS',
        width: 100,
        render: (record) => {
          setTimeout(() => {
            // Set the parent antd table cell with background color
            let row = document.querySelector(
              '[data-row-key="' + record.id + '"]'
            );
            let wosCell = row.lastChild;
            wosCell.classList.add(getWoSColorClass(record.wos));
          }, 100);
          return <span> {record.wos} </span>;
        },
      },
    ];
  };

  updateBoardForecast = () => {
    const { workingChanges } = this.state;

    let request = [];

    let groupedWorkingChanges = groupBy(workingChanges, 'boardForecastId');

    Object.entries(groupedWorkingChanges).forEach((entry) => {
      request.push({ boardForecastId: entry[0], changes: entry[1] });
    });

    this.setState(
      {
        updatingBoardForecast: true,
        workingChanges: [],
      },
      () => {
        this.updateForecast(request, () => {
          this.setState({
            updatingBoardForecast: false,
            updatedForecast: true,
          });
          toast.success('Updated forecast successfully !');
        }).catch((error) => {
          let errorMessage = ApiUtils.getErrorMessage(error);
          toast.error('Failed to update forecast - ' + errorMessage);
          this.setState({ updatingBoardForecast: false });
        });
      }
    );
  };

  showChartChanges = () => {
    this.setState({ chartChangesPopupVisible: true });
  };

  renderTable = (fin, location, forecastName, originalData, doNotTransform) => {
    let boardData = { ...this.state.boardData };

    boardData = originalData.boardForecast.filter(
      (elt) =>
        elt.itemNumber === fin &&
        elt.code === location &&
        elt.forecastName === forecastName &&
        elt.description !== 'Inventory' &&
        elt.description !== 'Previous Weeks'
    );

    boardData = orderBy(boardData, function (o) {
      return o.period;
    });

    if (!doNotTransform) boardData = this.getTransformedData(boardData);

    this.setState(
      {
        boardData,
        columns: this.getColumns(boardData),
      },
      () => this.props.getChanges({ boardForecast: boardData })
    );
  };

  getTransformedData = (data, currentLocationCode, currentForecastName) => {
    let transformedData = [];

    data = data.filter(
      (elt) =>
        elt.description !== 'Inventory' && elt.description !== 'Previous Weeks'
    );

    if (currentLocationCode && currentForecastName)
      data = data.filter(
        (elt) =>
          elt.code === currentLocationCode &&
          elt.forecastName === currentForecastName
      );

    data = orderBy(data, 'period');

    data.forEach((elt, index) => {
      let dataObj = { ...elt };
      dataObj.weekTitle = elt['description'];
      dataObj.weekPeriod = elt['period'];

      dataObj.changes = [];
      let weekObj = {};
      StringConstants.InventoryFields.forEach((inventory) => {
        weekObj[inventory.type] = {};
        inventory.fields.forEach((field) => {
          weekObj[inventory.type][field.name] = elt[field.key];
        });
      });

      dataObj.weekData = weekObj;
      transformedData.push(dataObj);
    });

    return transformedData;
  };

  getData = () => {
    const { boardData, workingChanges } = this.state;
    return {
      data: boardData,
      changes: workingChanges,
    };
  };

  checkIncompleteForecasts = () => {
    const { boardData } = this.state;
    const { currentLocationCode, currentForecastName } = this.props;
    let data = this.getTransformedData(
      boardData,
      currentLocationCode,
      currentForecastName
    );
    let lastIndexOfZero = -1;
    let nonZeroIndex = -1;
    let stopZeroCheck = false;
    let stopNonZeroCheck = false;
    let index = data.length - 1;
    reverse(data).forEach((elt) => {
      if (!stopZeroCheck && elt.forecast === 0) {
        lastIndexOfZero = index;
        stopZeroCheck = true;
      } else if (stopZeroCheck && !stopNonZeroCheck && elt.forecast !== 0) {
        nonZeroIndex = index;
        stopNonZeroCheck = true;
        return;
      }
      index--;
    });

    if (
      nonZeroIndex === lastIndexOfZero ||
      nonZeroIndex === -1 ||
      lastIndexOfZero === -1 ||
      lastIndexOfZero !== data.length - 1
    )
      this.setState({ hasIncompleteForecast: {} });
    else
      this.setState({
        hasIncompleteForecast: { nonZeroIndex, lastIndexOfZero },
      });
  };

  updateForecast = async (data, callback, bulkUpdate = false) => {
    try {
      const boardId = this.props.boardData.id;
      let currentLocationCode = this.props.selectedLocation;
      let currentForecastName = this.props.selectedForecast;
      const response = await BoardService.updateBoardForecast(boardId, {
        boardForeCasts: data,
        bulkUpdate,
      });
      let boardData = this.getTransformedData(
        response.data.boardForecast,
        currentLocationCode,
        currentForecastName
      );

      let prevWoSes = this.getWoSesBeforeUpdate(
        this.state.originalData,
        currentLocationCode,
        currentForecastName
      );

      boardData = boardData.map((elt, index) => {
        return {
          ...elt,
          prevWoS: prevWoSes[index],
        };
      });

      this.setState(
        {
          boardData,
          originalData: cloneDeep(boardData),
          columns: [
            ...this.getColumns(boardData),
            ...this.getWoSChangeColumns(),
          ],
          currentBoardData: response.data.boardForecast,
        },
        () => {
          this.props.refreshChart({ boardForecast: boardData });
          this.props.getChanges({ boardForecast: boardData });
          this.checkIncompleteForecasts();
        }
      );
      callback();
    } catch (error) {
      throw error;
    }
  };

  extendForecast = (forecastIndexObj) => {
    const { nonZeroIndex, lastIndexOfZero } = forecastIndexObj;
    let data = [...this.state.boardData];

    let nonZeroForecastValue = data[nonZeroIndex].forecast;

    let request = [];

    data = data.map((elt, index) => {
      if (index <= nonZeroIndex || index > lastIndexOfZero) return { ...elt };
      else {
        let weekObj = { ...elt.weekData };
        weekObj.outgoingInventory.Forecast = nonZeroForecastValue;
        request.push({
          boardForecastId: elt.id,
          changes: [
            ApiUtils.getForecastExtendChangeRequest(nonZeroForecastValue),
          ],
        });
        return {
          ...elt,
          forecast: nonZeroForecastValue,
          weekData: weekObj,
        };
      }
    });
    const bulkUpdate = true;
    this.updateForecast(
      request,
      () => {
        this.setState({
          extendingForecast: false,
          workingChanges: [],
        });
        toast.success('Extended forecast successfully !');
      },
      bulkUpdate
    ).catch((error) => {
      let errorMessage = ApiUtils.getErrorMessage(error);
      toast.error('Failed to extend forecast - ' + errorMessage);
      this.setState({ extendingForecast: false });
    });
  };

  render() {
    const {
      columns,
      workingChanges,
      editLeverModalVisible,
      selectedLever,
      prevBoardData,
      boardData,
      currentBoardData,
      updatingBoardForecast,
      updatedForecast,
      extendingForecast,
      chartChangesPopupVisible,
      hasIncompleteForecast,
    } = this.state;

    const { selectedLocation } = this.props;

    return (
      <>
        {updatingBoardForecast && (
          <>
            <div className='rendering-progress'></div>
            <Spin
              className='spinner'
              tip='Updating Forecast ...'
              size='large'
            />
          </>
        )}
        {extendingForecast && (
          <>
            <div className='rendering-progress'></div>
            <Spin
              className='spinner'
              tip='Extending Forecast ...'
              size='large'
            />
          </>
        )}
        <Card
          className='inventory-table-card'
          extra={
            boardData.length > 0 &&
            boardData[0].code &&
            boardData[0].code !== 'ALL_LOC' && (
              <>
                {!isEmpty(hasIncompleteForecast) ? (
                  <div className='extend-forecast-div'>
                    <Typography.Text>
                      {boardData[hasIncompleteForecast.nonZeroIndex + 1]
                        .period !==
                      boardData[hasIncompleteForecast.lastIndexOfZero].period
                        ? 'There are incomplete forecasts from ' +
                          getFullWeekDisplayText(
                            boardData[hasIncompleteForecast.nonZeroIndex + 1]
                          ) +
                          ' to ' +
                          getFullWeekDisplayText(
                            boardData[hasIncompleteForecast.lastIndexOfZero]
                          )
                        : 'There are incomplete forecasts for week ' +
                          getFullWeekDisplayText(
                            boardData[hasIncompleteForecast.lastIndexOfZero]
                          )}
                    </Typography.Text>
                    <Popconfirm
                      placement='topLeft'
                      title={
                        'This action would also update the forecast immediately, are you sure to continue?'
                      }
                      onConfirm={() =>
                        this.setState(
                          {
                            extendingForecast: true,
                          },
                          () => this.extendForecast(hasIncompleteForecast)
                        )
                      }
                      onCancel={() => {}}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button
                        type='link'
                        className='extend-forecast-btn'
                        disabled={extendingForecast}
                      >
                        <span> Extend Forecast </span>
                        <ArrowDownOutlined />
                      </Button>
                    </Popconfirm>
                  </div>
                ) : (
                  <div className='extend-forecast-div'> </div>
                )}
                <Space>
                  <Button
                    type='link'
                    disabled={isEmpty(workingChanges)}
                    onClick={() => this.updateBoardForecast()}
                  >
                    <span
                      className={
                        (isEmpty(workingChanges) ? 'red' : 'green') + '-circle'
                      }
                    ></span>
                    <span> Update Forecast </span>
                    <FileSyncOutlined />
                  </Button>
                  <Button
                    type='link'
                    disabled={!updatedForecast}
                    onClick={() => this.showChartChanges()}
                  >
                    <span> Show Chart Changes </span>
                    <BarChartOutlined />
                  </Button>
                </Space>
              </>
            )
          }
        >
          <Table
            dataSource={[...boardData]}
            columns={[...columns]}
            rowKey='id'
            scroll={{ x: 'max-content', y: 300 }}
            pagination={false}
          />
        </Card>
        {editLeverModalVisible && (
          <EditLeverModal
            visible={editLeverModalVisible}
            data={selectedLever}
            closeModal={() => this.setState({ editLeverModalVisible: false })}
            updateData={(data, inventoryType) =>
              this.updateData(data, inventoryType)
            }
          />
        )}
        {chartChangesPopupVisible && (
          <ChartChangesPopup
            visible={chartChangesPopupVisible}
            prevBoardData={prevBoardData}
            currentBoardData={currentBoardData}
            closePopup={() =>
              this.setState({ chartChangesPopupVisible: false })
            }
            selectedForecast={this.props.selectedForecast}
            locations={this.props.locations}
            currentLocation={selectedLocation}
          />
        )}
      </>
    );
  }
}

export default TableComponent;
