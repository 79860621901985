import './BoardPreview.css';

import { Button, Card, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import UrlGenerator from '../../api/UrlGenerator';
import { getFormattedFullDate } from '../../utils/DateUtils';

const { Meta } = Card;

class BoardPreview extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Card
        className='board-preview-card'
        hoverable
        actions={[
          <Link
            to={{
              pathname: UrlGenerator.getUiUrlWithPathParams(
                RouteConstants.VIEW_BOARD,
                {
                  id: data.id,
                }
              ),
              boardData: data,
            }}
          >
            <Button type='link' icon={<EditOutlined />} key='edit' />
          </Link>,
          <Popconfirm
            title='Are you sure to delete this board?'
            onConfirm={() => this.props.deleteBoard()}
            okText='Yes'
            cancelText='No'
          >
            <Button type='link' icon={<DeleteOutlined />} key='delete' />
          </Popconfirm>,
        ]}
      >
        <Link
          to={{
            pathname: UrlGenerator.getUiUrlWithPathParams(
              RouteConstants.VIEW_BOARD,
              {
                id: data.id,
              }
            ),
            boardData: data,
          }}
        >
          <Meta
            title={data.name}
            description={
              <>
                <p>{data.description}</p>
                <p>
                  {'Last updated : ' + getFormattedFullDate(data.updatedAt)}
                </p>
                <p>
                  {'Data synced at : ' +
                    getFormattedFullDate(data.navDataPulledAt)}
                </p>
              </>
            }
          />
        </Link>
      </Card>
    );
  }
}

export default BoardPreview;
