import { Button, Card, Collapse, List, Typography } from 'antd';
import {
  getChangeElement,
  getChangeLogObjectForExtendForecast,
} from '../../utils/DisplayUtils';
import { groupBy, isEmpty, orderBy } from 'lodash';

import BoardService from '../../services/BoardService';
import { DownloadOutlined } from '@ant-design/icons';
import LoadingComponent from '../../components/LoadingComponent';
import React from 'react';
import SecureComponent from '../../components/SecureComponent';
import { downloadExcel } from '../../utils/DownloadUtils';

const { Panel } = Collapse;

class BoardChangeLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originalData: [],
      allChanges: [],
      loadingChangeLog: true,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    BoardService.fetchChangeLog(id)
      .then((response) => {
        let allChanges = groupBy(response.data, function (o) {
          return o.itemNumber;
        });
        this.setState({
          originalData: response.data,
          allChanges,
          loadingChangeLog: false,
        });
      })
      .catch((error) => {
        this.setState({ loadingChangeLog: false });
      });
  }

  render() {
    const { id } = this.props.match.params;
    const { loadingChangeLog, originalData, allChanges } = this.state;
    if (loadingChangeLog)
      return <LoadingComponent title='Loading change logs ...' />;

    return (
      <Card
        title='All Changes Log'
        style={{ marginTop: 15 }}
        extra={
          <Button
            type='link'
            icon={<DownloadOutlined />}
            onClick={() =>
              downloadExcel(
                Object.values(
                  groupBy(originalData, function (o) {
                    return o.itemNumber + '_' + o.code + '_' + o.forecastName;
                  })
                ),
                id,
                true
              )
            }
            disabled={isEmpty(allChanges)}
          >
            Export Changes
          </Button>
        }
      >
        <List
          dataSource={Object.keys(allChanges)}
          renderItem={(item, index) => {
            let elt =
              allChanges[item] &&
              allChanges[item].length > 0 &&
              allChanges[item][0];

            let groupedChanges = groupBy(allChanges[item], function (o) {
              return o.code + '_' + o.forecastName;
            });
            return (
              <Collapse key={item} defaultActiveKey={Object.keys(allChanges)}>
                <Panel
                  header={elt.itemDescription + ' (' + elt.itemNumber + ')'}
                  key={item}
                >
                  <List
                    dataSource={Object.keys(groupedChanges)}
                    renderItem={(group) => {
                      let text = group.split('_');
                      getChangeLogObjectForExtendForecast(
                        orderBy(groupedChanges[group], 'period', 'asc')
                      );
                      return (
                        <List.Item key={group}>
                          <Typography.Title
                            level={5}
                            className='changes-group-title'
                          >
                            {text[0] + ' | ' + text[1]}
                          </Typography.Title>
                          <List
                            className='changes-group-list'
                            dataSource={orderBy(
                              groupedChanges[group],
                              'period',
                              'asc'
                            )}
                            renderItem={(change) => {
                              return getChangeElement(change);
                            }}
                          />
                        </List.Item>
                      );
                    }}
                  />
                </Panel>
              </Collapse>
            );
          }}
        />
      </Card>
    );
  }
}

export default SecureComponent(BoardChangeLogs);
