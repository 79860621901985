import { getFormattedDate, getFormattedFullDate } from './DateUtils';

import StringConstants from '../constants/StringConstants';
import XlsxPopulate from 'xlsx-populate';
import { getChangeText } from './DisplayUtils';
import moment from 'moment';
import { orderBy } from 'lodash';

const populateSheet = (workbook, data, sheetName) => {
  let sheet = workbook.addSheet(sheetName);
  if (workbook.sheet('Sheet1')) workbook.deleteSheet('Sheet1'); // Remove default initial sheet
  let currentIndex = 1;

  // Set headers
  StringConstants.ExcelHeaders.forEach((header, index) => {
    sheet
      .row(index + 1)
      .cell(1)
      .value(header.label)
      .style('bold', true);

    sheet
      .row(index + 1)
      .cell(2)
      .value(
        header.fields.length > 1
          ? header.fields.map((field) => data[0][field]).join(' ( ') + ' ) '
          : data[0][header.fields[0]]
      );

    currentIndex++;
  });

  sheet.column(1).width(30);
  sheet.column(2).width(60);
  sheet.column(3).width(60);

  sheet
    .row(currentIndex + 1)
    .cell(1)
    .value('Week Period')
    .style('bold', true);

  sheet
    .row(currentIndex + 1)
    .cell(2)
    .value('Changes')
    .style('bold', true);

  sheet
    .row(currentIndex + 1)
    .cell(3)
    .value('Updated At')
    .style('bold', true);

  currentIndex = currentIndex + 2;

  data.forEach((change) => {
    if (change.changeLogs.length === 1) {
      sheet
        .row(currentIndex)
        .cell(1)
        .value('Week of ' + getFormattedDate(change.period))
        .style('verticalAlignment', 'center');

      sheet
        .row(currentIndex)
        .cell(2)
        .value(getChangeText(change.changeLogs[0]));

      sheet
        .row(currentIndex)
        .cell(3)
        .value(getFormattedFullDate(moment(change.changeLogs[0].createdAt)));

      currentIndex++;
    } else {
      sheet
        .range(currentIndex, 1, currentIndex + change.changeLogs.length - 1, 1)
        .merged(true)
        .value('Week of ' + getFormattedDate(change.period))
        .style('verticalAlignment', 'center');

      let sortedChangeLogs = orderBy(change.changeLogs, 'createdAt', 'desc');

      sortedChangeLogs.forEach((changeElt, index) => {
        sheet
          .row(currentIndex + index)
          .cell(2)
          .value(getChangeText(changeElt));

        sheet
          .row(currentIndex + index)
          .cell(3)
          .value(getFormattedFullDate(moment(changeElt.createdAt)));
      });

      currentIndex = currentIndex + change.changeLogs.length;
    }
  });
};

const writeToFile = (workbook, fileName) => {
  return workbook.outputAsync().then(function (blob) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  });
};

const getBlankWorkBook = () => {
  return XlsxPopulate.fromBlankAsync();
};

const getUniqueSheetName = (sheetData) => {
  return sheetData && sheetData.length > 0
    ? (
        sheetData[0].itemNumber +
        '_' +
        sheetData[0].code +
        '_' +
        sheetData[0].forecastName
      ).substring(0, 31)
    : '-';
};

export const downloadExcel = (data, boardId, isMultipleSheetDownload) => {
  let uniqueSheetName = !isMultipleSheetDownload
    ? getUniqueSheetName(data)
    : '-';

  let fileName = !isMultipleSheetDownload
    ? 'Board #' + boardId + ' - Item ' + uniqueSheetName + ' - Change Log.xlsx'
    : 'Board #' + boardId + ' All Changes.xlsx';

  getBlankWorkBook().then((workbook) => {
    if (!isMultipleSheetDownload) {
      const sortedData = sortDataForExcel(data);
      populateSheet(workbook, sortedData, uniqueSheetName);
    } else {
      data.forEach((currentSheetData) => {
        const sortedData = sortDataForExcel(currentSheetData);
        uniqueSheetName = getUniqueSheetName(sortedData);
        populateSheet(workbook, sortedData, uniqueSheetName);
      });
    }
    return writeToFile(workbook, fileName);
  });
};

const sortDataForExcel = (data) => {
  const dataForExcel = data.filter((record) => record.changeLogs.length > 0);
  dataForExcel.forEach((record, index) => {
    const sortedChangeLogs = orderBy(record.changeLogs, 'createdAt', 'desc');
    dataForExcel[index].changeLogs = sortedChangeLogs;
  });
  const sortedData = dataForExcel.sort((record1, record2) => {
    return (
      new Date(record2.changeLogs[0].createdAt) -
      new Date(record1.changeLogs[0].createdAt)
    );
  });
  return sortedData;
};
