import './ChartComponent.css';

import { Col, Radio, Row, Select } from 'antd';
import { cloneDeep, isEqual, isUndefined, max, min, orderBy } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import StringConstants from '../../constants/StringConstants';
import { getFormattedDate } from '../../utils/DateUtils';
import moment from 'moment';
import noData from 'highcharts/modules/no-data-to-display';

const { Option } = Select;

noData(Highcharts);

class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originalData: props.boardData,
      boardData: props.boardData,
      isChangesView: props.isChangesView,
      options: {
        chart: {
          type: 'column',
          height: 300,
        },
        title: {
          text: '',
        },
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
          },
        },
        xAxis: {
          title: {
            text: 'Week',
          },
        },
        yAxis: {
          title: {
            text: StringConstants.AnalysisTabs.WoS,
          },
          labels: {
            overflow: 'justify',
          },
        },
        series: [
          {
            data: [],
            showInLegend: false,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {},
      },
      currentView: StringConstants.POLevels[0],
      currentChartType: StringConstants.AnalysisTabs.WoS,
    };
  }

  renderChart = (fin, location, forecastName, originalData) => {
    let boardData = { ...this.state.boardData };

    boardData.boardForecast = originalData.boardForecast.filter(
      (elt) =>
        elt.itemNumber === fin &&
        elt.code === location &&
        elt.forecastName === forecastName &&
        elt.description !== 'Inventory' &&
        elt.description !== 'Previous Weeks'
    );

    boardData.boardForecast = orderBy(boardData.boardForecast, function (o) {
      return o.period;
    });

    this.setState(
      {
        boardData,
      },
      () => {
        this.updateSeries();
      }
    );
  };

  componentDidMount() {
    const { isChangesView } = this.state;
    if (isChangesView) this.updateSeries();
  }

  getTransformedData = (chartType) => {
    let currentChartType = chartType ? chartType : this.state.currentChartType;
    const { boardData, currentView } = this.state;
    let boardForecast = cloneDeep(boardData.boardForecast);

    let data = [];
    let currentYear = undefined;
    let prevWarehouseInventory = 0;
    boardForecast.forEach((elt, index) => {
      let weekNumber = elt.description.split(' ')[1];
      let week = 'Wk ' + weekNumber;
      let year = moment(elt.period).get('year');
      let xAxisCategory =
        isUndefined(currentYear) || currentYear !== year
          ? week + ' ' + year
          : weekNumber;
      if (
        currentChartType === StringConstants.AnalysisTabs.Inventory &&
        currentView.level === 'Warehouse only'
      ) {
        let warehouseInventory =
          index === 0
            ? elt.forecastedProjectedInventory - elt.scheduledReceipt
            : elt.scheduledProduction +
              elt.scheduledTransferIn +
              elt.forecast +
              elt.scheduledTransferOut +
              prevWarehouseInventory;
        data.push({
          x: xAxisCategory,
          y: warehouseInventory,
        });
        prevWarehouseInventory = warehouseInventory;
      } else
        data.push({
          x: xAxisCategory,
          y: elt[
            currentChartType === StringConstants.AnalysisTabs.WoS
              ? currentView.wosField
              : currentView.inventoryField
          ],
        });
      currentYear = year;
    });

    return data;
  };

  updateSeries = (chartType, yMin, yMax) => {
    let currentChartType = chartType ? chartType : this.state.currentChartType;
    let options = { ...this.state.options };
    let xAxis = { ...options.xAxis };
    let yAxis = { ...options.yAxis };
    let series = [...options.series];
    let tooltip = { ...options.tooltip };
    yAxis.title.text =
      currentChartType === StringConstants.AnalysisTabs.Inventory
        ? StringConstants.AnalysisTabs.Inventory + ' (Cases)'
        : StringConstants.AnalysisTabs.WoS;
    series[0].name = this.state.currentView.level;
    let transformedData = cloneDeep(this.getTransformedData(currentChartType));

    if (transformedData.every((elt) => elt.y === 0)) series[0].data = [];

    xAxis.categories = transformedData.map((elt) => elt.x);
    series[0].data = transformedData.map((elt) =>
      currentChartType === StringConstants.AnalysisTabs.WoS && elt.y >= 104
        ? -1
        : elt.y
    );

    const { boardData } = this.state;

    tooltip.formatter = function () {
      let elt = getFormattedDate(
        boardData.boardForecast[this.point.index].period
      );
      return `Week of <b>${elt}</b> <br /> ${yAxis.title.text} <b> ${this.y} </b>`;
    };

    yAxis.max = yMax ? yMax : max(series[0].data);
    yAxis.min = yMin ? yMin : min(series[0].data);

    if (currentChartType === StringConstants.AnalysisTabs.WoS) {
      options.plotOptions = { column: { colorByPoint: true } };
      options.colors = series[0].data.map((elt) => {
        return elt === -1
          ? '#9254de'
          : elt >= 16
          ? '#fadb14'
          : elt >= 8
          ? 'gray'
          : '#f5222d';
      });
      if (series[0].data.some((val) => val < 0)) {
        let maxY = max(series[0].data);
        yAxis.max = maxY > 0 ? maxY : 5;
        yAxis.min = -5;
        yAxis.startOnTick = false;
      }
    } else {
      options.plotOptions = { column: { colorByPoint: false } };
      options.colors = ['#1890ff'];
    }

    options.tooltip = tooltip;
    options.xAxis = xAxis;
    options.yAxis = yAxis;
    options.series = series;

    this.setState({ options });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.refresh, prevProps.refresh))
      this.setState({ boardData: this.props.boardData }, () =>
        this.updateSeries()
      );
  }

  render() {
    const { currentView, currentChartType, boardData, isChangesView } =
      this.state;

    return (
      <div className='full-width-row'>
        {!isChangesView && (
          <Row className='analysis-tabs'>
            <Col xs={24}>
              <Radio.Group
                value={currentView}
                onChange={(event) =>
                  this.setState({ currentView: event.target.value }, () => {
                    this.updateSeries();
                  })
                }
                style={{ marginTop: 16, marginBottom: 16 }}
                buttonStyle='solid'
              >
                {StringConstants.POLevels.map((levelElt) => (
                  <Radio.Button
                    value={levelElt}
                    key={levelElt.level}
                    style={{ backgroundColor: levelElt.color }}
                  >
                    {levelElt.level}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Col>
          </Row>
        )}
        {!isChangesView && (
          <Row>
            <Col xs={24} lg={12}>
              <span>
                Period:
                <b>
                  {' ' +
                    getFormattedDate(boardData.boardForecast[0].period) +
                    ' '}
                </b>
                to
                <b>
                  {' ' +
                    getFormattedDate(
                      boardData.boardForecast[
                        boardData.boardForecast.length - 1
                      ].period
                    )}
                </b>
              </span>
            </Col>
            <Col xs={24} lg={12} className='chart-type-selector'>
              <span> Chart Type: </span>
              <Select
                value={currentChartType}
                onChange={(value) =>
                  this.setState({ currentChartType: value }, () =>
                    this.updateSeries()
                  )
                }
              >
                {Object.keys(StringConstants.AnalysisTabs).map((tab) => {
                  return (
                    <Option key={tab} value={StringConstants.AnalysisTabs[tab]}>
                      {StringConstants.AnalysisTabs[tab]}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        )}

        <HighchartsReact
          options={this.state.options}
          highcharts={Highcharts}
          updateArgs={[true]}
        />
      </div>
    );
  }
}

export default ChartComponent;
