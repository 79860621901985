import { List, Typography } from 'antd';
import {
  capitalize,
  forEach,
  isEmpty,
  isNull,
  isUndefined,
  lowerCase,
  orderBy,
  startCase,
  upperCase,
} from 'lodash';
import { getFormattedDate, getFormattedFullDate } from './DateUtils';

import StringConstants from '../constants/StringConstants';
import moment from 'moment';

export const getDisplayString = (value, isStartCase = false) => {
  if (isEmpty(value)) return '-';
  if (value.includes('_') || value === upperCase(value)) {
    if (isStartCase) return startCase(lowerCase(value));
    else return capitalize(lowerCase(value));
  }
  return value;
};

export const getKeyString = (value) => {
  return value
    .split(' ')
    .map((elt) => elt.toUpperCase())
    .join('_');
};

export const getChangeText = (change) => {
  let changeText = startCase(change.fieldName);
  if (change.operationType === 'REPLACE')
    changeText += ' changed from ' + change.oldValue + ' to ' + change.newValue;
  else if (change.operationType === 'ADD_PERCENTAGE')
    changeText +=
      (change.inputValue > 0 ? ' increased by ' : ' decreased by ') +
      Math.abs(change.inputValue) +
      '% from ' +
      change.oldValue +
      ' to ' +
      change.newValue;
  else if (change.operationType === 'EXTEND') {
    if (change.startsAt === change.endsAt) {
      changeText += ' extended with value ' + change.newValue;
    } else {
      changeText +=
        ' extended from Week of ' +
        getFormattedDate(change.startsAt) +
        ' to ' +
        getFormattedDate(change?.endsAt) +
        ' with value ' +
        change.newValue;
    }
  } else
    changeText +=
      (change.inputValue > 0 ? ' increased by ' : ' decreased by ') +
      Math.abs(change.inputValue) +
      ' from ' +
      change.oldValue +
      ' to ' +
      change.newValue;

  return changeText;
};

export const getChangeElement = (change) => {
  return (
    change.changeLogs.length > 0 && (
      <List.Item
        key={change.itemNumber + '_' + change.code + '_' + change.period}
      >
        <Typography.Title level={5}>
          <span style={{ fontWeight: 500 }}>
            {'Week of ' + getFormattedDate(change.period)}
          </span>
        </Typography.Title>
        <List
          className='changes-list'
          dataSource={orderBy(change.changeLogs, 'createdAt', 'desc')}
          renderItem={(change) => {
            return (
              <List.Item key={change.id}>
                <p>{getChangeText(change)}</p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    paddingLeft: 10,
                    color: 'gray',
                  }}
                >
                  {'Updated at ' +
                    getFormattedFullDate(moment(change.createdAt))}
                </p>
              </List.Item>
            );
          }}
        ></List>
      </List.Item>
    )
  );
};

export const renderUserRole = (role) => {
  return (
    <span
      style={{
        color:
          role === StringConstants.UserRole.SUPER_ADMIN ? '#52c41a' : '#fa8c16',
      }}
    >
      {getDisplayString(role)}
    </span>
  );
};

export const renderUserStatus = (status) => {
  return (
    <span
      style={{
        color:
          status === StringConstants.UserStatus.ACTIVE ? '#52c41a' : '#f5222d',
      }}
    >
      {getDisplayString(status)}
    </span>
  );
};

export const getWoSColorClass = (wos) => {
  return wos >= 104
    ? 'wos-err'
    : wos >= 16
    ? 'wos-high'
    : wos >= 8
    ? 'wos-mid'
    : 'wos-low';
};

export const getFullWeekDisplayText = (boardForecastElt) => {
  return (
    boardForecastElt.weekTitle +
    ', ' +
    moment(boardForecastElt.period).get('year')
  );
};

export const getChangeLogObjectForExtendForecast = (groupedChanges) => {
  let extendForecast = {};
  forEach(groupedChanges, (change, index) => {
    forEach(change.changeLogs, (changeLogs, secondaryIndex) => {
      if (
        changeLogs &&
        !isNull(changeLogs.groupId) &&
        !isUndefined(changeLogs.groupId) &&
        changeLogs.operationType !== 'EXTEND'
      ) {
        if (Object.keys(extendForecast).includes(changeLogs.groupId)) {
          extendForecast[changeLogs.groupId].endsAt = change.period;
          change.changeLogs.splice(secondaryIndex, 1);
        } else {
          changeLogs.operationType = 'EXTEND';
          const tempObject = {};
          tempObject.index = index;
          tempObject.secondaryIndex = secondaryIndex;
          tempObject.startsAt = change.period;
          tempObject.endsAt = change.period;
          extendForecast[changeLogs.groupId] = tempObject;
        }
      }
    });
  });
  forEach(Object.values(extendForecast), (object) => {
    groupedChanges[object.index].changeLogs[object.secondaryIndex].endsAt =
      object.endsAt;
    groupedChanges[object.index].changeLogs[object.secondaryIndex].startsAt =
      object.startsAt;
  });
};
