const HttpConstants = {
  GET_METHOD: 'GET',
  GET_BLOB_METHOD: 'GET_BLOB',
  POST_METHOD: 'POST',
  PATCH_METHOD: 'PATCH',
  PUT_METHOD: 'PUT',
  DELETE_METHOD: 'DELETE',
};

export default HttpConstants;
