import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router';
import { fetchUser, fetchUserFailed } from '../../actions/user';
import {
	isInternalWoSUser,
	saveToken,
	verifyToken,
} from '../../utils/auth/auth';

import ApiUtils from '../../utils/ApiUtils';
import LoadingComponent from '../../components/LoadingComponent';
import RouteConstants from '../../constants/RouteConstants';
import UrlGenerator from '../../api/UrlGenerator';
import { connect } from 'react-redux';
import { getUserDataFromCookie } from '../../utils/auth/AuthUtils';
import { isEmpty } from 'lodash';
import { parseHash } from '../../utils/auth/auth0';
import { toast } from 'react-toastify';

import { MsalContext } from "@azure/msal-react";

class RedirectComponent extends Component {

	static contextType = MsalContext;
	setRoute = async (path, token, tokenPayload) => {
		try {
			let payload = {};
			if (token) this.props.fetchUser(payload, path);
			else this.props.history.push(path);
		} catch (error) {
			this.props.fetchUserFailed(error, path);
			let errorMessage = ApiUtils.getErrorMessage(error);
			toast.error(errorMessage);
		}
	};

	componentDidMount = async () => {
		const loggedInUserData = getUserDataFromCookie();
		const instance = this.context.instance;
		let token = await instance.acquireTokenSilent({
			account: instance.getActiveAccount(),
		});
		if (!isEmpty(loggedInUserData) && isInternalWoSUser(token.idTokenClaims)) {
			let redirectionRoute = this.getRedirectionRoute(RouteConstants.HOME);
			this.props.fetchUser({}, redirectionRoute);
			return;
		} else {
			parseHash(async (err, result) => {
				if (result == null) {
					//const token = await getTokenForBrowser();
					const instance = this.context.instance;
					let token = await instance.acquireTokenSilent({
						account: instance.getActiveAccount(),
					});
					saveToken(token.accessToken);
					// if (isInternalHMSUser(tokenResponse.idToken))
					if (!token) {
						this.setRoute(RouteConstants.LOGIN);
						return;
					} else if (!isInternalWoSUser(token.idTokenClaims)) {
						this.setRoute(RouteConstants.NOTAGROUPMEMBER);
						return;
					} else {
						let redirectionRoute = this.getRedirectionRoute();
						this.setRoute(redirectionRoute, token.accessToken);
						return;

					}
				}
				if (err) {
					console.error('Error signing in', err);
					this.setRoute(RouteConstants.LOGIN);
					return;
				}

				verifyToken(result.idToken).then(async (valid) => {
					if (valid) {
						saveToken(result.idToken, result.accessToken);
						let redirectionRoute = this.getRedirectionRoute();

						this.setRoute(
							redirectionRoute,
							result.idToken,
							result.idTokenPayload
						);
						return;
					} else {
						this.setRoute(RouteConstants.LOGIN);
						return;
					}
				});
			});
		}
	};




	getRedirectionRoute = (defaultRoute = RouteConstants.HOME) => {
		let redirectionRoute = defaultRoute;
		if (this.props.location.search) {
			const route = UrlGenerator.getParamsFromUrl(
				this.props.location.search,
				'redirect'
			);
			if (route) redirectionRoute = route;
		}
		return redirectionRoute;
	};

	render() {
		if (this.props.route && this.props.route.redirectPath) {
			return <Redirect to={this.props.route.redirectPath} />;
		}

		return (
			<div style={{ height: '90vh' }}>
				<LoadingComponent title='Logging you in ...' />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		route: state.route,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUser: (payload, redirectPath) =>
			dispatch(fetchUser(payload, redirectPath)),
		fetchUserFailed: (payload, redirectPath) =>
			dispatch(fetchUserFailed(payload, redirectPath)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(RedirectComponent));
