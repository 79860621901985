let wosValue = 0;
let inventoryValue = 0;

const filters = {
  item: [
    'GV Tilapia Fillets 10/2LB Bag',
    'GV Whole Tilapia 8/3LB Bag',
    'GV Tilapia Fillets 8/4LB Bag',
    'MJR Tilapia Fillets 10/2LB Bag',
    'GV Shrimp 5/32oz Bag 31-40 Raw PD VB',
    'GV Shrimp 12/12oz Bag 16-22 Raw',
  ],
  locationCode: ['MTCL-BAL', 'PREF-GULF', 'PREF-HOU', 'LACS-LA'],
  species: ['SHRIMP', 'FINFISH'],
};

const wosData = {
  item: 'All',
  locationCode: 'All',
  species: 'All',
  data: [...Array(52)].map((_, index) => {
    if (index % 7 === 0)
      wosValue = wosValue + Math.floor(Math.random() * 10 + 10);
    else wosValue = wosValue - 1;
    return wosValue;
  }),
};

const inventoryData = {
  item: 'All',
  locationCode: 'All',
  species: 'All',
  data: [...Array(52)].map((_, index) => {
    if (index % 7 === 0)
      inventoryValue =
        inventoryValue + Math.floor(Math.random() * 50000 + 5000);
    else inventoryValue = inventoryValue - 5000;
    return inventoryValue;
  }),
};

const tableData = [
  {
    item: 'GV Tilapia Fillets 10/2LB Bag',
    locationCode: 'MTCL-BAL',
    species: 'FINFISH',
    weekData: [...Array(52)].map((_, index) => {
      let inventoryForWeek = Math.floor(Math.random() * 10000);
      let incomingInventoryTotal = Math.floor(inventoryForWeek * Math.random());
      let outgoingInventoryTotal = Math.floor(inventoryForWeek * Math.random());
      return {
        incomingInventory: {
          plannedOrders: Math.floor(incomingInventoryTotal / 4),
          purchaseOrders: Math.floor(incomingInventoryTotal / 4),
          productionOrders: Math.floor(incomingInventoryTotal / 4),
          transfersIn: Math.floor(incomingInventoryTotal / 4),
          total: incomingInventoryTotal,
        },
        outgoingInventory: {
          grossRequirement: Math.floor(outgoingInventoryTotal / 3),
          forecast: Math.floor(outgoingInventoryTotal / 3),
          transfersOut: Math.floor(outgoingInventoryTotal / 3),
          total: outgoingInventoryTotal,
        },
        wos: Math.floor(Math.random() * 10),
      };
    }),
  },
  {
    item: 'GV Tilapia Fillets 10/2LB Bag',
    locationCode: 'PREF-GULF',
    species: 'FINFISH',
    weekData: [...Array(52)].map((_, index) => {
      let inventoryForWeek = Math.floor(Math.random() * 10000);
      let incomingInventoryTotal = Math.floor(inventoryForWeek * Math.random());
      let outgoingInventoryTotal = Math.floor(inventoryForWeek * Math.random());
      return {
        incomingInventory: {
          plannedOrders: Math.floor(incomingInventoryTotal / 4),
          purchaseOrders: Math.floor(incomingInventoryTotal / 4),
          productionOrders: Math.floor(incomingInventoryTotal / 4),
          transfersIn: Math.floor(incomingInventoryTotal / 4),
          total: incomingInventoryTotal,
        },
        outgoingInventory: {
          grossRequirement: Math.floor(outgoingInventoryTotal / 3),
          forecast: Math.floor(outgoingInventoryTotal / 3),
          transfersOut: Math.floor(outgoingInventoryTotal / 3),
          total: outgoingInventoryTotal,
        },
        wos: Math.floor(Math.random() * 10),
      };
    }),
  },
  {
    item: 'GV Whole Tilapia 8/3LB Bag',
    locationCode: 'MTCL-BAL',
    species: 'FINFISH',
    weekData: [...Array(52)].map((_, index) => {
      let inventoryForWeek = Math.floor(Math.random() * 10000);
      let incomingInventoryTotal = Math.floor(inventoryForWeek * Math.random());
      let outgoingInventoryTotal = Math.floor(inventoryForWeek * Math.random());
      return {
        incomingInventory: {
          plannedOrders: Math.floor(incomingInventoryTotal / 4),
          purchaseOrders: Math.floor(incomingInventoryTotal / 4),
          productionOrders: Math.floor(incomingInventoryTotal / 4),
          transfersIn: Math.floor(incomingInventoryTotal / 4),
          total: incomingInventoryTotal,
        },
        outgoingInventory: {
          grossRequirement: Math.floor(outgoingInventoryTotal / 3),
          forecast: Math.floor(outgoingInventoryTotal / 3),
          transfersOut: Math.floor(outgoingInventoryTotal / 3),
          total: outgoingInventoryTotal,
        },
        wos: Math.floor(Math.random() * 10),
      };
    }),
  },
];

const columnsOrder = {
  incomingInventory: {
    'Planned Orders': 1,
    'Purchase Orders': 2,
    'Production Orders': 3,
    'Transfers In': 4,
    Total: 5,
  },
  outgoingInventory: {
    'Gross Requirement': 1,
    Forecast: 2,
    'Transfers Out': 3,
    Total: 4,
  },
};

export const sampleData = {
  filters,
  wosData,
  inventoryData,
  tableData,
  columnsOrder,
};
