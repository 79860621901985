import './ChartChangesPopup.css';

import { Col, Modal, Row, Select, Space, Spin, Typography } from 'antd';
import { isEqual, max, min, orderBy } from 'lodash';

import ChartComponent from './ChartComponent';
import React from 'react';
import StringConstants from '../../constants/StringConstants';
import { getFormattedDate } from '../../utils/DateUtils';

const { Option } = Select;

class ChartChangesPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prev: undefined,
      current: undefined,
      loading: true,
      currentChartType: StringConstants.AnalysisTabs.WoS,
      currentLocation: this.props.currentLocation,
    };
  }

  componentDidMount() {
    this.processData();
  }

  processData = () => {
    const { prevBoardData, currentBoardData, selectedForecast } = this.props;
    const { currentLocation } = this.state;
    this.setState(
      {
        prev: {
          ...prevBoardData,
          boardForecast: orderBy(
            prevBoardData.boardForecast.filter(
              (elt) =>
                elt.code === currentLocation &&
                elt.forecastName === selectedForecast &&
                elt.description !== 'Inventory' &&
                elt.description !== 'Previous Weeks'
            ),
            'period',
            'asc'
          ),
        },
        current: {
          ...prevBoardData,
          boardForecast: orderBy(
            currentBoardData.filter(
              (elt) =>
                elt.code === currentLocation &&
                elt.forecastName === selectedForecast &&
                elt.description !== 'Inventory' &&
                elt.description !== 'Previous Weeks'
            ),
            'period',
            'asc'
          ),
        },
        loading: false,
      },
      () => {
        let yMin = min([
          this.getMinYAxis(this.state.prev),
          this.getMinYAxis(this.state.current),
        ]);
        let yMax = max([
          this.getMaxYAxis(this.state.prev),
          this.getMaxYAxis(this.state.current),
        ]);
        this.prevChartRef.updateSeries(this.state.currentChartType, yMin, yMax);
        this.currentChartRef.updateSeries(
          this.state.currentChartType,
          yMin,
          yMax
        );
      }
    );
    return {
      prev: this.state.prev,
      current: this.state.current,
    };
  };

  getMinYAxis = (data) => {
    return min(
      data.boardForecast.map(
        (elt) =>
          elt[
            this.state.currentChartType === StringConstants.AnalysisTabs.WoS
              ? 'wos'
              : 'suggestedProjectedInventory'
          ]
      )
    );
  };

  getMaxYAxis = (data) => {
    return max(
      data.boardForecast.map((elt) => {
        return elt[
          this.state.currentChartType === StringConstants.AnalysisTabs.WoS
            ? 'wos'
            : 'suggestedProjectedInventory'
        ];
      })
    );
  };

  render() {
    const { visible, locations } = this.props;
    const { prev, current, loading, currentChartType, currentLocation } =
      this.state;

    return (
      <Modal
        visible={visible}
        onCancel={() => this.props.closePopup()}
        width={'90%'}
        footer={null}
      >
        {loading || !prev || !current ? (
          <div className='center-text'>
            <Space>
              <Spin />
              <span> Processing ...</span>
            </Space>
          </div>
        ) : (
          <>
            <Row className='chart-type-row'>
              <Col xs={24} lg={12}>
                <span>
                  Period:
                  <b>
                    {' ' + getFormattedDate(prev.boardForecast[0].period) + ' '}
                  </b>
                  to
                  <b>
                    {' ' +
                      getFormattedDate(
                        prev.boardForecast[prev.boardForecast.length - 1].period
                      )}
                  </b>
                </span>
                <span>
                  {' '}
                  Showing for:{' '}
                  <Select
                    className='filter-select-25w'
                    showSearch={true}
                    onChange={(value) =>
                      this.setState(
                        {
                          currentLocation: value,
                          prev: undefined,
                          current: undefined,
                        },
                        () => {
                          setTimeout(() => {
                            let { prev, current } = this.processData();
                            let yMin = min([
                              this.getMinYAxis(prev),
                              this.getMinYAxis(current),
                            ]);
                            let yMax = max([
                              this.getMaxYAxis(prev),
                              this.getMaxYAxis(current),
                            ]);
                            this.prevChartRef.updateSeries(
                              this.state.currentChartType,
                              yMin,
                              yMax
                            );
                            this.currentChartRef.updateSeries(
                              this.state.currentChartType,
                              yMin,
                              yMax
                            );
                          }, 2000);
                        }
                      )
                    }
                    value={currentLocation}
                  >
                    {locations &&
                      locations.map((elt) => (
                        <Option key={elt} value={elt}>
                          {elt === 'ALL_LOC' ? 'All Locations' : elt}
                        </Option>
                      ))}
                  </Select>
                </span>
                <p>
                  {isEqual(prev, current) && (
                    <Typography.Text type='warning'>
                      {' '}
                      Data is unchanged for this location !{' '}
                    </Typography.Text>
                  )}
                </p>
              </Col>
              <Col xs={24} lg={12} className='chart-type-selector'>
                <span> Chart Type: </span>
                <Select
                  value={currentChartType}
                  onChange={(value) =>
                    this.setState(
                      {
                        currentChartType: value,
                      },
                      () => {
                        let yMin = min([
                          this.getMinYAxis(prev),
                          this.getMinYAxis(current),
                        ]);
                        let yMax = max([
                          this.getMaxYAxis(prev),
                          this.getMaxYAxis(current),
                        ]);
                        this.prevChartRef.updateSeries(value, yMin, yMax);
                        this.currentChartRef.updateSeries(value, yMin, yMax);
                      }
                    )
                  }
                >
                  {Object.keys(StringConstants.AnalysisTabs).map((tab) => {
                    return (
                      <Option
                        key={tab}
                        value={StringConstants.AnalysisTabs[tab]}
                      >
                        {StringConstants.AnalysisTabs[tab]}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row className='change-row' justify='space-between'>
              <Col xs={24} lg={10}>
                <Typography.Title
                  level={4}
                  type='danger'
                  className='chart-title'
                >
                  {' '}
                  Previous Board Data{' '}
                </Typography.Title>
                <ChartComponent
                  ref={(ref) => (this.prevChartRef = ref)}
                  boardData={prev}
                  isChangesView={true}
                  refresh={true}
                />
              </Col>
              <Col xs={24} lg={10}>
                <Typography.Title
                  level={4}
                  type='success'
                  className='chart-title'
                >
                  {' '}
                  Current Board Data{' '}
                </Typography.Title>
                <ChartComponent
                  ref={(ref) => (this.currentChartRef = ref)}
                  boardData={current}
                  isChangesView={true}
                  refresh={true}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    );
  }
}

export default ChartChangesPopup;
