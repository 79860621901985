import HttpConstants from '../constants/HttpConstants';
import UrlGenerator from './UrlGenerator';
import axios from 'axios';
import cookie from 'react-cookies';
import { isEmpty } from 'lodash';

function makeApiCall(usecase, httpMethod, data) {
  return makeApiCallInternal(usecase, httpMethod, false, data);
}

function makeApiCallWithAuthentication(
  usecase,
  httpMethod,
  data,
  isContentTypeMultiPart
) {
  return makeApiCallInternal(
    usecase,
    httpMethod,
    true,
    data,
    isContentTypeMultiPart
  );
}

function makeApiCallInternal(
  usecase,
  httpMethod,
  authenticatedCall,
  data,
  isContentTypeMultiPart
) {
  let { url, params } = UrlGenerator.updatePathParamsForApiUrl(
    UrlGenerator.generateUrl(usecase),
    data
  );
  let headers = {};

  if (authenticatedCall)
    headers = {
      Authorization: 'Bearer ' + cookie.load('token'),
    };

  const requestConfig = {
    headers,
    method: httpMethod,
  };

  switch (httpMethod) {
    case HttpConstants.GET_BLOB_METHOD:
      requestConfig.responseType = 'blob';
      requestConfig.method = 'get';
      break;
    case HttpConstants.GET_METHOD:
    case HttpConstants.DELETE_METHOD:
      if (params) {
        let updatedUrlConfig = UrlGenerator.addQueryParamsToApiUrl(url, params);
        url = updatedUrlConfig.url;
        params = updatedUrlConfig.params;
      }
      break;
    default:
      break;
  }

  requestConfig.url = url;
  if (!isEmpty(params)) {
    if (httpMethod === HttpConstants.GET_BLOB_METHOD)
      requestConfig.params = params;
    else requestConfig.data = params;
  }

  if (isContentTypeMultiPart)
    return new Promise((response, reject) => {
      axios
        .post(requestConfig.url, data, {
          headers: { ...headers, 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201 || res.status === 204) {
            response(res);
          } else {
            throw res;
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });

  return new Promise((response, reject) => {
    axios
      .request(requestConfig)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          response(res);
        } else {
          throw res;
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

const ApiManager = {
  makeApiCall,
  makeApiCallWithAuthentication,
};

export default ApiManager;
